<template>
  <Body>
    <page-header
      :title="title"
      back_text="Аккаунты"
      back_url="/accounts"
    ></page-header>
    <Page :max_width="800">
      <Card>
        <base-input
          label="Client_id"
          placeholder="client_id"
          :mb="20"
          v-model="data.avito.client_id"
        ></base-input>
        <base-input
          label="Client_secret"
          placeholder="client_secret"
          :mb="20"
          v-model="data.avito.client_secret"
        ></base-input>

        <errors-box v-if="errors.length" :errors="errors"></errors-box>

        <base-button
          :active="data.avito.client_id && data.avito.client_secret"
          :loading="saving"
          @click="save"
          >Сохранить</base-button
        >
      </Card>
      <card style="margin-top: 50px">
        <h3>Где брать client_id и client_secret</h3>
        <p>
          В кабинете Авито перейдите в раздел "Для профессионалов" -
          "Интеграции":
        </p>
        <img src="/img/avito_data.png" width="100%" alt="" />
      </card>
    </Page>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import PageHeader from "@/components/PageHeader";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";
import axios from "axios";
import ErrorsBox from "@/components/ErrorsBox";
export default {
  name: "EditAccount",
  data() {
    return {
      title: "",
      data: {
        avito: {
          client_id: null,
          client_secret: null,
        },
      },
      saving: false,
      errors: [],
    };
  },
  components: { ErrorsBox, Card, Page, PageHeader, Body },
  methods: {
    save() {
      this.saving = true;
      let data = this.data.avito;
      axios.post("accounts/save", data).then((res) => {
        if (res.data.success) {
          this.$router.push("/accounts");
        } else {
          this.errors = res.data.errors;
          this.saving = false;
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.id === "new") {
      this.title = "Новый аккаунт";
    }
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style scoped></style>
