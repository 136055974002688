<template>
  <div class="chat_window">
    <div class="chat_header">
      <div class="chat_header_left">
        <div class="avatar_box">
        <img :src="info.avatar">
          <div class="avatar_source">
            <img src="../../assets/images/avito.svg">
          </div>
        </div>
        <div>
          <div class="chat_header_left_name">
            {{ info.name }}
          </div>
          <a class="chat_header_left_title" target="_blank" :href="info.url">{{ info.title }}</a>
        </div>
      </div>
    </div>
    <div class="chat_messages_box" ref="chat">
      <div style="flex: 1"></div>
      <div
          :class="{ chat_msg_my: m.direction !== 'in' }"
          v-for="(m, $i) in messages"
          :key="$i"
          class="chat_msg_wrapper"
      >
        <div class="chat_msg_date_div" v-if="dayDiff(m, messages[$i - 1])">
          {{ dayDiff(m, messages[$i - 1]) }}
        </div>

        <div class="chat_msg" :class="{ chat_msg_notify: m.notify }">
          <div
              class="chat_msg_text"
          >{{ m.content.text }}
          </div>
          <div
              class="chat_msg_date"
              :class="[
              { chat_msg_date_read: m.is_read === 1 },
              { chat_msg_date_attaches: m.attaches }
            ]"
              v-if="m.from === 'moderator'"
          >
            {{ formatDate(m.created) }}
            <i class="fa fa-check" v-if="m.is_read" aria-hidden="true"></i>
          </div>
          <div
              class="chat_msg_date"
              :class="[{ chat_msg_date_attaches: m.attaches }]"
              v-else
          >
            {{ formatDate(m.created) }}
          </div>
        </div>
        <div
            class="chat_msg_date_div"
            v-if="$i === messages.length - 1 && lastDayDiff(m)"
        >
          {{ lastDayDiff(m) }}
        </div>
      </div>
    </div>
    <div class="chat_input_box">
      <div style="position: relative; flex: 1; height: 100%">
        <input
            placeholder="Напишите сообщение..."
            class="chat_input"
            type="text"
            contenteditable="true"
            ref="chat_input"
            @keydown.enter="enter"
            v-model="text"
        >

      </div>
      <div class="chat_input_box_right">
        <button @click="sendButton"><icon-send></icon-send></button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";
import IconSend from "@/components/icons/IconSend.vue";

export default {
  name: "ChatWindow",
  components: {IconSend},
  data() {
    return {
      text: "",
      later: {
        text: "",
        notify_text: "",
        auto: false,
        date: null,
        type: "later_msg",
        auto_lost: false
      },
      show_later_suggests: false,
      later_suggests: [],
      messages: [],
      info: {
        user: {}
      },
      interval: {},
      arrowCounter: 0,
      show_input_suggest: true
    };
  },
  methods: {
    load(top = false) {
      axios
          .post("vacancies/get-messages", {chat_id: this.$route.params.id})
          .then(res => {
            let go_top = false;
            if (this.messages.length !== res.data.list.length) go_top = true;

            this.messages = res.data.list.reverse();

            this.info.name = res.data.info.users[0].name
            this.info.avatar = res.data.info.users[0].public_user_profile.avatar.default
            this.info.title = res.data.info.context.value.title
            this.info.url = res.data.info.context.value.url

            if (go_top) {
              this.toTop();
            }
          });
    },
    toTop() {
      this.$nextTick(() => {
        if (typeof this.$refs.chat !== "undefined")
          this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
      });
    },
    formatDate(value) {
      if (value) {
        return format(new Date(value * 1000), "k:mm");
      }
    },
    formatDataDay(value){
      if (value) {
        return format(new Date(value * 1000), "d MMMM", {locale: ru});
      }
    },
    dayDiff(one, two) {
      if (typeof two != "undefined") {
        if (
            format(new Date(one.created * 1000), "dd") !==
            format(new Date(two.created * 1000), "dd")
        ) {
          //return moment(String(two.createdAt)).format("DD MMMM");
          return format(new Date(two.created * 1000), "d MMMM", {
            locale: ru
          });
        } else {
          return false;
        }
      }
    },
    lastDayDiff(m) {
      let today = {};

      today.created = Math.round(new Date().getTime() / 1000);

      return this.dayDiff(today, m);
    },
    enter(e) {

      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.send();
      }
    },
    sendButton() {
      this.send();
    },
    send(image = null) {
      axios
          .post("vacancies/send-message", {
            chat_id: this.$route.params.id,
            msg: this.text,
          })
          .then(res => {
            if (res.data.success) {
              this.load();
              this.text = "";
            } else {
              alert("error");
            }
          });
    },
    changeText(text) {
      this.$refs.chat_input.innerText = text;
      this.$refs.templates_popup.close();
      this.text = text;
      this.show_input_suggest = false;
    }
  },
  mounted() {
    this.load(true);
  }
};
</script>

<style scoped lang="less">
@import "../../assets/vars";

.chat_window {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat_header {
  background: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid @color_lines_gray;
}

.chat_header_left {
  display: flex;
  gap: 15px;
  align-items: center;


  img {
    height: 45px;
    border-radius: 100px;
  }
}
.chat_header_left_name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}

.chat_header_left_title {
  font-size: 13px;
}

.chat_input {
  flex: 1;
  padding: 7px 10px;
  border-radius: 0.375rem;
  outline: none;
  position: relative;
  height: 100%;
}

.chat_input_later_notify {
  min-height: 50px;
  background: rgba(11, 144, 168, 0.18);
}

.chat_messages_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: @color_bg;
}

.later_button {
  margin-right: 15px;
  color: #7a7a7a;
}

.later_suggest_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  button {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 14px;
    color: #575757;
  }

  button:last-child {
    border-bottom: none;
  }
}

.suggested_phrases_popup {
  position: absolute;
  bottom: 60px;
  left: 0;
  opacity: 0.7;
  width: 100%;

  .later_box {
    width: 100%;
  }

  .later_suggest_box {
    width: 100%;

    button {
      text-align: left;
      padding: 10px 10px;
      font-size: 14px;
      color: #575757;
      background: #fff;
      margin-bottom: 5px;
      border-radius: 9px;
    }

    .is-active {
      background: #2ec0c3;
      color: #fff;
      box-shadow: 0px 2px 9px #00000029;
    }
  }
}

.suggested_phrases_popup_active {
  opacity: 1;
}

.chat_input_box {
  border-top: 1px solid @color_lines_gray;
  background: #fff;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    border: none;
    outline: none;
    width: 100%;
    padding: 0 20px;
    height: 45px;
  }
}

.chat_input_box_right {
  display: flex;
  align-items: center;

  button {
    padding: 7px 20px;

  }
}

.chat_msg {
  background: #fff;
  flex: 0;
  width: max-content;
  border-radius: 6px 12px 12px 6px;
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
}

.chat_msg_text {
  padding: 6px 0.5rem 0.375rem 0.625rem;
  max-width: 450px;
}

.chat_msg_images {
  max-width: 450px;

  img,
  video {
    max-width: 100%;
  }
}

.chat_msg_my {
  align-self: flex-end;
  border-radius: 12px 6px 6px 12px;
  width: 100%;
  display: grid;

  .chat_msg {
    background: #d3f1af;
    justify-self: end;
  }
}

.chat_msg_date_div {
  margin: 10px 0;
  margin-top: 20px;
  border-radius: 25px;
  background: transparent;
  color: @color_gray_links;
  width: max-content;
  align-self: center;
  justify-self: center;
  font-weight: 600;
  font-size: 13px;
}

.chat_msg_date {
  font-size: 12px;
  color: #999999;
  align-self: end;
  padding: 3px 4px 1px 0;
}

.chat_msg_date_read {
  color: #2dce89;
}

.chat_msg_wrapper {
  white-space: pre-line;
  display: grid;
}

.chat_msg_date_attaches {
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  right: 4px;
  bottom: 3px;
  border-radius: 13px;
  padding: 4px 8px 2px;
}

.chat_messages_box::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.chat_messages_box::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.chat_messages_box::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent;
  background-clip: content-box;
}

.later_box {
  width: 700px;
}

.chat_msg_notify {
  background: #fffece !important;
}
</style>
