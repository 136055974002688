<template>
  <div class="login_box">
    <div class="login_box_inner">
      <icon-logo :width="126" style="margin-bottom: 20px"></icon-logo>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IconLogo from "@/components/icons/IconLogo";
export default {
  name: "LoginBox",
  components: { IconLogo },
};
</script>

<style scoped lang="less">
@import "../assets/vars";

.login_box {
  background: white;
  border: 1px solid @color_lines_gray;
  border-radius: 20px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  justify-content: center;
  align-items: center;
}

.login_box_inner {
  width: 80%;
}
</style>
