<template>
  <svg :width="width" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512">
    <path fill="#0068E1" d="
  M 20.81 0.00
  L 24.06 0.00
  Q 29.28 0.92 30.95 1.78
  Q 261.22 119.83 491.48 237.91
  Q 498.86 241.70 501.22 244.24
  C 508.37 251.93 506.65 266.30 496.98 271.26
  Q 264.14 390.68 31.28 510.06
  Q 28.95 511.25 23.44 512.00
  L 21.44 512.00
  Q 10.45 511.04 7.26 501.50
  C 5.24 495.50 6.72 489.98 8.46 482.18
  Q 29.01 390.36 49.69 298.57
  A 0.71 0.70 1.2 0 1 50.26 298.03
  L 290.94 256.34
  Q 292.23 256.12 290.98 255.70
  Q 289.43 255.18 287.04 254.77
  Q 168.63 234.47 50.64 214.04
  A 1.31 1.30 -1.3 0 1 49.59 213.04
  Q 28.05 117.27 6.73 22.01
  C 4.37 11.44 9.47 1.66 20.81 0.00
  Z"
    />
  </svg>
</template>
<script>
export default {
  name: "IconSend",
  props: {width: {default: 24}},
};
</script>
