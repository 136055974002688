<template>
  <Body>
    <page-header title="Чат-боты">
      <template v-slot:right> </template>
    </page-header>
    <Page :max_width="800">
      <base-button
        @click="$router.push('/auto-replies/new')"
        size="big"
        style="margin-bottom: 20px"
        >Новый чат-бот</base-button
      >
      <card v-for="(a, $i) in auto_replies" :key="$i" :mb="15">
        <div>
          <router-link
            class="auto_reply_title"
            :to="{ name: 'auto_replies_edit', params: { id: a.id } }"
            >{{ a.name }}</router-link
          >
        </div>
        <Badge v-if="a.default">По умолчанию</Badge>
      </card>
    </Page>
  </Body>
</template>

<script>
import PageHeader from "../components/PageHeader.vue";
import BaseButton from "@/components/ui/BaseButton";
import Body from "@/components/Body";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";
import axios from "axios";
import Badge from "@/components/ui/Badge";
export default {
  name: "AutoReplies",
  components: { Badge, Card, Page, Body, BaseButton, PageHeader },
  data() {
    return {
      auto_replies: [],
    };
  },
  methods: {
    load() {
      axios.post("auto-replies/get-all").then((res) => {
        console.log(res);
        this.auto_replies = res.data.templates;
      });
    },
  },
  mounted() {
    this.load();
  },
  metaInfo() {
    return {
      title: "Чат-боты",
    };
  },
};
</script>

<style scoped lang="less">
.auto_reply_title {
  font-size: 18px;
  font-weight: 600;
}
</style>
