<template>
  <login-box>
    <base-input placeholder="Email" v-model="email" :mb="15"> </base-input>

    <base-input
      placeholder="Пароль"
      type="password"
      v-model="password"
      :mb="30"
      @keyup.enter="login"
    >
    </base-input>

    <ErrorsBox v-if="errors.length" :errors="errors"></ErrorsBox>

    <base-button @click="login" :loading="loading">Войти</base-button>

    <div class="login_reg_div">
      <router-link to="/registration">Регистрация</router-link>
    </div>
  </login-box>
</template>

<script>
import LoginBox from "@/components/LoginBox";
import ErrorsBox from "@/components/ErrorsBox";
export default {
  name: "Login",
  components: { ErrorsBox, LoginBox },
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      const { email, password } = this;
      this.$store
        .dispatch("auth_request", { email: email, password: password })
        .then((res) => {
          if (this.$route.params.route) {
            this.$router.push({ name: this.$route.params.route.name });
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((res) => {
          this.errors = res.data.errors;
          this.loading = false;
        });
    },
  },
  metaInfo() {
    return {
      title: "Вход",
    };
  },
};
</script>

<style scoped>
.login_reg_div {
  margin-top: 40px;
}

.login_errors {
}
</style>
