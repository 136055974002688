<template>
<div class="app">
  <side-bar></side-bar>
  <div class="main_wrapper">
    <RouterView></RouterView>
  </div>
</div>
</template>

<script>
import SideBar from "./SideBar.vue";
export default {
name: "Main",
  components: {SideBar}
}
</script>

<style lang="less" scoped>
@import "../assets/vars";
.app {
  width: 100%;
  height: 100vh;
  background: @color_bg;
  display: flex;
}

.main_wrapper {
  flex: 1;
}

body {

}

</style>