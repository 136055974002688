<template>
  <Body>
    <page-header
      :title="title"
      back_text="Чат-боты"
      back_url="/auto-replies"
    ></page-header>
    <Page :max_width="800">
      <card
        v-for="(a, $i) in data.actions"
        :key="$i"
        style="margin-bottom: 20px"
      >
        <base-input v-model="info.name" label="Название" :mb="20"></base-input>
        <div v-if="a.type === 'site'" style="font-weight: 600">
          Текст ответа
        </div>
        <textarea
          class="form-control auto_reply_input"
          id=""
          cols="30"
          rows="10"
          v-model="a.text"
        ></textarea>
      </card>
      <Card>
        <check-box v-model="info.default" :margin="20">По умолчанию</check-box>
        <div v-if="info.default" style="margin-bottom: 20px">
          <strong>Внимание!</strong> Этот чат-бот будет применяться ко
          <strong>всем</strong> вакансиям без подлюченного чат-бота.
        </div>
        <errors-box v-if="errors.length" :errors="errors"></errors-box>

        <base-button :loading="saving" @click="save">Сохранить</base-button>
      </Card>
    </Page>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import PageHeader from "@/components/PageHeader";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";
import axios from "axios";
import ErrorsBox from "@/components/ErrorsBox";
export default {
  name: "EditAutoReply",
  data() {
    return {
      title: "",
      info: {
        name: "Мой чат-бот",
        default: false,
      },
      data: {
        actions: [{ text: "", type: "site" }],
      },
      saving: false,
      errors: [],
    };
  },
  components: { ErrorsBox, Card, Page, PageHeader, Body },
  methods: {
    load() {
      axios
        .post("auto-replies/get", { id: this.$route.params.id })
        .then((res) => {
          console.log(res);
          this.info.name = res.data.template.name;
          this.info.default = res.data.template.default;
          this.data = { ...this.data, ...res.data.template.data };
          this.title = res.data.template.name;
        });
    },
    save() {
      this.saving = true;
      let id = null;
      if (this.$route.params.id !== "new") id = this.$route.params.id;

      let data = {
        id: id,
        name: this.info.name,
        default: this.info.default,
        data: this.data,
      };

      axios.post("auto-replies/save", data).then((res) => {
        if (res.data.success) {
          this.$router.push("/auto-replies");
        } else {
          this.errors = res.data.errors;
          this.saving = false;
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.id === "new") {
      this.title = "Новый чат-бот";
    } else {
      this.load();
    }
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style scoped lang="less">
.auto_reply_input {
  min-height: 100px;
  padding: 20px;
}
</style>
