<template>
  <div class="badge" :class="[`badge-type-${type}`]"><slot></slot></div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    type: { default: "default" },
  },
};
</script>

<style scoped lang="less">
@import "../../assets/vars";
.badge {
  font-size: 12px;
  padding: 3px 6px;
  background: #ebebeb;
  color: @color_gray_links;
  display: inline;
  border-radius: 7px;
  font-weight: 600;
}

.badge-type-red {
  color: @color_red;
}

.badge-type-black {
  color: #000000;
}
</style>
