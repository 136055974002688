<template>
  <div class="page_body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Body",
};
</script>

<style scoped lang="less">
.page_body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
