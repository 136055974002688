<template>
  <Body>
    <page :loading="true"> </page>
  </Body>
</template>

<script>
import Page from "@/components/Page";
import axios from "axios";
import Body from "@/components/Body";
export default {
  name: "AvitoCallback",
  components: { Body, Page },
  mounted() {
    axios
      .post("accounts/add-avito", { code: this.$route.query.code })
      .then((res) => {
        this.$router.push({ name: "accounts", query: { after_bind: "true" } });
      });
  },
};
</script>

<style scoped></style>
