<template>
  <div class="sidebar">
    <div class="sidebar_wrapper">
      <div>
        <router-link to="/dashboard">
          <icon-logo :width="126"></icon-logo>
        </router-link>

        <div class="links">
          <router-link to="/vacancies">Вакансии</router-link>
          <router-link to="/auto-replies">Чат-боты</router-link>
          <router-link to="/chats">Чаты</router-link>
          <router-link to="/accounts">Аккаунты</router-link>
        </div>
      </div>
      <div class="links">
        <router-link to="/settings">Настройки</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from "../components/icons/IconLogo.vue";
export default {
  name: "SideBar",
  components: { IconLogo },
  methods: {},
};
</script>

<style scoped lang="less">
@import "../assets/vars";
.sidebar {
  width: 100%;
  max-width: 200px;
  border-right: 5px solid @color_lines_gray;
  background: white;
}

.sidebar_wrapper {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  a {
    font-size: 16px;
    font-weight: 600;
    color: @color_gray_links;
    text-decoration: none;
    margin-bottom: 11px;
    transition: 0.2s;
    margin-left: -18px;
  }

  a:hover {
    color: @color_base;
  }

  a:before {
    content: "❯";
    margin-right: 10px;
    opacity: 0;
    transition: 0.2s;
  }

  .router-link-active {
    margin-left: -12px;
  }

  .router-link-active,
  .router-link-active:hover {
    color: @color_dark;
    cursor: auto;
  }

  .router-link-active:before {
    opacity: 1;
  }
}
</style>
