<template>
  <div class="popup_wrapper" v-if="show">
    <div class="popup_box">
      <button @click="hide" class="close_button">
        <icon-close :width="18"></icon-close>
      </button>
      <slot></slot>
    </div>
    <div class="fade" @click="hide"></div>
  </div>
</template>

<script>
import IconClose from "@/components/icons/IconClose";
export default {
  name: "Popup",
  components: { IconClose },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    hide() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
.popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 100;
}

.fade {
  background: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup_box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  padding: 30px;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  z-index: 200;
}

.close_button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
}

.close_button:hover {
  svg {
    fill: #000000;
  }
}
</style>
