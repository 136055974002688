var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat_list_wrapper"},[_c('div',{staticClass:"chat_list_chats"},[_c('transition-group',{staticClass:"list-group list-group-flush list",attrs:{"name":"list","tag":"ul"}},_vm._l((_vm.items),function(item,$i){return _c('li',{key:item.id + 'list_item',staticClass:"list-group-item px-0 chat_item",class:[
          { chat_item_active: item.id === parseInt(_vm.$route.params.id) },
          { chat_item_show: item.status === 'active' || _vm.show_closed }
        ],on:{"click":function($event){return _vm.go(item.id)}}},[_c('div',{staticClass:"align-items-center chat_item_row"},[(item.status === 'active')?_c('div',{staticClass:"chat_list_active_green"}):_vm._e(),_c('div',{staticClass:"col-auto chat_list_img"},[_c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar_box"},[_c('img',{attrs:{"src":item.users[0].public_user_profile.avatar.default}}),_c('div',{staticClass:"avatar_source"},[_c('img',{attrs:{"src":require("../../assets/images/avito.svg")}})])])])]),_c('div',{staticClass:"chat_item_right"},[_c('div',{staticClass:"col ml--2 chat_list_text"},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(item.users[0].name))]),_c('small',[(item.direction === 'in')?_c('strong',[_vm._v("👨 ")]):_vm._e(),_vm._v(_vm._s(item.last_message.content.text))])]),_c('div',{staticClass:"col-auto chat_list_right"},[_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")])])]),(
            _vm.items[$i + 1] &&
              item.status === 'active' &&
              _vm.items[$i + 1].status !== 'active' &&
              _vm.show_closed
          )?_c('div',{staticClass:"chat_list_closed_item"},[_vm._v(" закрытые ")]):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.items.length),expression:"!items.length"}],staticClass:"chat_list_no_work "},[_c('i',{staticClass:"ni ni-check-bold text-blue"}),_c('div',[_vm._v("Нет открытых чатов...")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }