<template>
  <Body>
  <Page>
    <div class="dashboard_wrapper">
      <div style="width: 80%; max-width: 900px">
        <Card>
          <h1>Добро пожаловать!</h1>
          <p>Несколько слов об этом сервисе.</p>
          <p>
            GdeJob для HR позволяет загружать и управлять вашими вакансиями с различных работных сайтов. На данный
            момент мы поддерживаем Avito и частично HH.
          </p>
          <p><strong>Как начать работу с кабинетом Avito:</strong></p>
          <ul>
            <li>
              <router-link to="/accounts">Привязать</router-link>
              аккаунт
              Avito
            </li>
            <li>
              Теперь вы получить ваши
              <router-link to="/vacancies">вакансии</router-link> и работать с ними
            </li>
          </ul>
          <p><strong>Как начать работу с кабинетом HH:</strong></p>
          <p>Интеграция с HH находится в тестовом режиме. Если вы хотите быть в числе тестировщиков, пожалуйста, <a href="mailto:partners@m.gdejob.com">напишите нам</a>.</p>
        </Card>
      </div>
    </div>
  </Page>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";

export default {
  name: "Dashboard",
  components: {Card, Page, Body},
};
</script>

<style scoped lang="less">
.dashboard_wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  .card {
    padding: 50px;
  }
}

h1 {
  margin-bottom: 40px;
}
</style>
