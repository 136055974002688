<template>
  <Body>
    <page-header title="Настройки">
      <template v-slot:right> </template>
    </page-header>
    <Page :max_width="800">
      <base-button @click="exit" type="red">Выйти</base-button>
    </Page>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import PageHeader from "@/components/PageHeader";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";
import axios from "axios";
export default {
  name: "Accounts",
  components: { Card, Page, PageHeader, Body },
  data() {
    return {};
  },
  methods: {
    exit() {
      this.$store.dispatch("auth_logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  mounted() {},
  metaInfo() {
    return {
      title: "Настройки",
    };
  },
};
</script>

<style scoped lang="less"></style>
