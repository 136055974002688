<template>
  <div>
    <notifications></notifications>
    <RouterView />
  </div>
</template>

<script>
import axios from "axios";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Мои вакансии",
    // all titles will be injected into this template
    titleTemplate: "%s | GdeJob",
  },
  methods: {},
  mounted() {
    axios.interceptors.request.use(
      (config) => {
        this.$store.commit("loading", true);
        return config;
      },
      (err) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.$store.commit("loading", false);
        return response;
      },
      (err) => {
        console.log(err);
        let res = err.response;
        if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
          return new Promise((resolve, reject) => {
            this.$store.dispatch("auth_logout");
            this.$router.push({
              name: "login",
              params: { route: this.$route },
            });
            reject(err);
          });
        }
      }
    );

    if (
      !this.$store.state.auth &&
      this.$route.name !== "login" &&
      this.$route.name !== "registration"
    ) {
      this.$router.push({ name: "login" });
    }

    if (this.$store.state.auth && this.$route.name === "login") {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style lang="less">
@import "assets/vars";
html {
  font-family: Inter, "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;

  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.2px;
  line-height: 21px;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  transition: color 0.5s, background-color 0.5s;
  -webkit-font-smoothing: antialiased;

  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

textarea {
  font-family: Inter, "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

p,
ul {
  margin-bottom: 20px;
}

h1,
h3 {
  margin-bottom: 20px;
}

textarea,
select,
input,
button {
  outline: none;
  font-family: Inter, "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */

  /* Will place small red box on the right of input (positioning carries over) */
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: flex-start;
  margin-top: auto;
  margin-right: 0px;
}

button {
  background: none;
  border: none;
  appearance: none;
  padding: 0;
  cursor: pointer;
}

a {
  color: @color_base;
  text-decoration: none;
}

a:hover {
  color: @color_black;
}

.app {
  min-width: 1200px;
}

.link_clear {
  text-decoration: none;
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

.my_select .vs__search::placeholder,
.my_select .vs__dropdown-toggle {
  border: 1px solid @color_lines_gray;
  height: 40px;
  padding: 0 14px;
  border-radius: 7px;
  width: 100%;
  font-size: 16px;
  background: white;
  overflow: hidden;
}

.my_select .vs__dropdown-menu {
  border: 1px solid @color_lines_gray;
  border-top: none;
  top: calc(100% - 2px);
}

.my_select .vs__dropdown-option--highlight {
  background: @color_base;
  color: white;
}

.my_select .vs__dropdown-option {
  padding: 3px 14px;
}

.my_select .vs__search,
.my_select .vs__search:focus {
  padding: 0;
  margin: 0;
}
.my_select .vs__search::placeholder {
  border: none;
  padding: 0;
  opacity: 0.4;
}

.my_select .vs__selected-options {
  padding: 0;
}
.my_select .vs__selected {
  margin: 0;
  padding: 0;
  line-height: 17px;
}

.my_select .vs__actions {
  margin: 0;
  padding: 0;
}

.vs--single.vs--open .vs__selected {
  margin: 0;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.my_select .vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.my_select .vs__clear {
  fill: @color_red;
}

.my_select .vs__fade-enter-active,
.my_select .vs__fade-leave-active {
  transition: none;
}

.form-group {
  width: 100%;
}

.form-control {
  border: 1px solid @color_lines_gray;
  height: 40px;
  padding: 0 14px;
  border-radius: 7px;
  width: 100%;
  font-size: 16px;
}

.form-control::placeholder {
  color: black;
  opacity: 0.4;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.form-control-label {
  font-weight: 600;
}
</style>
