<template>
  <div
    class="selected_box_wrapper"
    :style="{ right: 10 + scrollbarWidth + 'px' }"
  >
    <div>
      Всего выбрано: <strong>{{ count }}</strong>
    </div>
    <div class="selected_box_right">
      <base-button
        style="margin-right: 20px"
        type="rounded_gray"
        @click="$emit('unbind-reply')"
        >Отвязать чат-бот</base-button
      >
      <base-button type="rounded" @click="$emit('bind-reply')"
        >Привязать чат-бот</base-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedBox",
  props: {
    count: {},
    scrollbarWidth: { default: 0 },
  },
};
</script>

<style scoped lang="less">
@import "../assets/vars";

.selected_box_wrapper {
  position: absolute;
  z-index: 100;
  background: @color_bg;
  left: 10px;
  bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 7px 38px;
  border: 1px solid @color_lines_gray;

  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  border-radius: 7px;
}

.selected_box_right {
  display: flex;
}
</style>
