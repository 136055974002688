<template>
  <Body>
    <page-header title="Аккаунты">
      <template v-slot:right> </template>
    </page-header>
    <Page :max_width="800" :loading="changing_state">
      <div style="margin-bottom: 20px">
        Подключено аккаунтов: <strong>{{ accounts.length }}</strong>
      </div>
      <Card style="margin-bottom: 20px" v-for="(a, $i) in accounts" :key="$i">
        <h3>{{ a.title }}</h3>
        <div>
          Сайт: <strong>{{ a.type }}</strong>
        </div>
        <div>
          ID: <strong>{{ a.user_id }}</strong>
        </div>
        <div style="margin-top: 20px">
          <check-box :checked="a.active" @change="changeStatus(a.id)"
            >Активен</check-box
          >
        </div>
      </Card>
      <base-button @click="$refs.popup.open()" size="big"
        >Подключить аккаунт</base-button
      >
    </Page>
    <popup ref="popup">
      <div class="bind_account_wrapper">
        <a
          class="link_clear"
          href="https://avito.ru/oauth?response_type=code&client_id=L2Ec0mnOb6dvXnrzqs2s&scope=messenger:read,messenger:write,job:write,job:cv,job:vacancy,job:negotiations,user:read,items:info"
        >
          <base-button type="white">
            <img
              width="20"
              style="margin-right: 10px"
              src="../assets/images/avito.svg"
              alt=""
            />
            Подключить аккаунт Авито
          </base-button>
        </a>

          <base-button type="white">
            <img
                width="20"
                style="margin-right: 10px"
                src="../assets/images/hh.svg"
                alt=""
            />
            Подключить аккаунт HH
          </base-button>
      </div>
    </popup>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import PageHeader from "@/components/PageHeader";
import Page from "@/components/Page";
import Card from "@/components/ui/Card";
import axios from "axios";
import Popup from "@/components/Popup";
export default {
  name: "Accounts",
  components: { Popup, Card, Page, PageHeader, Body },
  data() {
    return {
      accounts: [],
      changing_state: false,
    };
  },
  methods: {
    load() {
      axios.post("accounts/get-all").then((res) => {
        this.accounts = res.data.accounts;
        this.changing_state = false;
      });
    },
    changeStatus(id) {
      this.changing_state = true;
      axios.post("accounts/change-status", { id: id }).then((res) => {
        this.load();
        this.$notify("Статус изменен!");
      });
    },
  },
  mounted() {
    this.load();
    if (this.$route.query.after_bind) {
      this.$notify("Аккаунт привязан.");
    }
  },
  metaInfo() {
    return {
      title: "Аккаунты",
    };
  },
};
</script>

<style scoped lang="less">
.bind_account_wrapper {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
</style>
