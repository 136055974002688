<template>
  <div class="check_wrapper" :class="{ 'check-disabled': disabled }">
    <label class="check option" :style="{ 'margin-bottom': margin + 'px' }">
      <input
        class="check__input"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        @change="$emit('change', $event.target.checked)"
      />
      <span class="check__box"></span>
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    checked: {},
    margin: {
      default: 0,
    },
    disabled: { default: false },
  },
  model: {
    prop: "checked",
    event: "change",
  },
};
</script>

<style scoped lang="less">
@import "../../assets/vars";
.check_wrapper {
  min-height: 15px;
  min-width: 15px;
  display: flex;
  font-size: 16px;
  line-height: 15px;
}
.check {
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  color: @color_dark;
}

.option {
  display: block;
  margin-bottom: 0.5em;
}

.check__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.check__box {
  position: absolute;
  margin-left: -30px;
  width: 15px;
  height: 15px;
  background-image: url("../../assets/images/off_check.svg");
}

/* Checked */

.check__input:checked + .check__box {
  background-image: url("../../assets/images/on_check.svg");
}

.check-disabled {
  opacity: 0.4;
}
</style>
