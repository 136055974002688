<template>
  <Body>
    <div class="chat_big_wrapper">
      <div class="chat_big_wrapper_list">
        <keep-alive>
          <chat-list></chat-list>
        </keep-alive>
      </div>
      <div v-if="!$route.meta.chat" class="chat_clear_window">
        <icon-chats :width="350"></icon-chats>
      </div>
      <router-view :key="$route.path"></router-view>
    </div>
  </Body>
</template>

<script>
import ChatWindow from "@/components/chat/ChatWindow";
import ClientCard from "@/components/chat/ClientCard";
import ChatList from "@/components/chat/ChatList";
import axios from "axios";
import IconBuddhist from "@/assets/IconBuddhist";
import PageHeader from "@/components/PageHeader.vue";
import Page from "@/components/Page.vue";
import IconChats from "@/components/icons/IconChats.vue";
export default {
  name: "Chats",
  components: {IconChats, Page, PageHeader, IconBuddhist, ChatList, ClientCard, ChatWindow },
  methods: {

  },
  mounted() {
  },
  metaInfo() {
    return {
      title: "Чаты",
    };
  },
  destroyed() {},
  beforeDestroy() {
    console.log("destroyed");
  }
};
</script>

<style lang="less">
@import "../assets/vars";
.chat_big_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
}
.chat_big_wrapper_window {
  flex: 1;
}
.chat_big_wrapper_list {
  background: #fff;
  //box-shadow: 7px 0 1rem 0 rgba(136, 152, 170, 0.26) !important;
  border-right: 1px solid @color_lines_gray;
  z-index: 1;
}
.chat_big_wrapper_list_in_chat {
  flex: 1;
}

.chat_right {
  display: flex;
  flex: 1;
}

.chat_clear_window {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
