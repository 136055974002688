<template>
  <div class="chat_right">
    <div class="chat_big_wrapper_window">
      <chat-window></chat-window>
    </div>
  </div>
</template>

<script>
import ChatWindow from "@/components/chat/ChatWindow";
import ClientCard from "@/components/chat/ClientCard";
export default {
  name: "ChatView",
  components: { ClientCard, ChatWindow },
  mounted() {
    console.log("mount");
  }
};
</script>

<style scoped></style>
