<template>
  <svg :width="width" viewBox="0 0 367 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="49" cy="49" r="49" fill="url(#paint0_linear_1084_44)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M83.6464 40.0295C80.9748 37.3578 76.6433 37.3578 73.9717 40.0295L54.6222 59.3789L47.8486 52.6054C45.177 49.9338 40.8467 49.935 38.1751 52.6066C35.5035 55.2782 35.5035 59.6098 38.1751 62.2814L54.6222 78.7284L83.6464 49.7042C86.318 47.0326 86.318 42.7011 83.6464 40.0295ZM59.6845 45.2537C62.3561 42.5821 62.3562 38.2506 59.6845 35.579L43.2375 19.1319L14.2132 48.1562C11.5416 50.8278 11.5416 55.1593 14.2132 57.8309C16.8848 60.5025 21.2164 60.5025 23.888 57.8309L43.2375 38.4814L50.011 45.255C52.6826 47.9266 57.0129 47.9253 59.6845 45.2537Z" fill="white"/>
    <path d="M141.573 72.1117C138.152 72.1117 135.33 71.3751 133.108 69.902C130.909 68.4289 129.327 66.3011 128.364 63.5186C127.401 60.7127 127.05 57.3456 127.31 53.4173L127.833 45.5257C128.27 38.9319 130.383 33.9163 134.173 30.4791C137.986 27.0419 143.325 25.3232 150.191 25.3232C152.301 25.3232 154.247 25.4752 156.031 25.7792C157.817 26.0598 159.433 26.4339 160.878 26.9016C162.347 27.3692 163.613 27.8836 164.678 28.4448L165.013 37.7043C163.285 36.9093 161.34 36.2078 159.177 35.5999C157.04 34.9685 154.553 34.6529 151.718 34.6529C147.593 34.6529 144.508 35.5882 142.463 37.4588C140.419 39.306 139.266 42.2054 139.004 46.1571L138.516 53.5226C138.321 56.4688 138.861 58.7485 140.137 60.3619C141.436 61.9753 143.562 62.782 146.515 62.782C147.827 62.782 148.976 62.595 149.962 62.2209C150.972 61.8234 151.815 61.3089 152.489 60.6776C153.187 60.0463 153.749 59.3448 154.175 58.5732L154.742 50.0152L155.97 53.242L147.32 53.277L147.88 44.8243H165.631L164.384 63.6589H153.628C153.169 65.2723 152.476 66.722 151.547 68.008C150.643 69.2707 149.381 70.2761 147.762 71.0244C146.167 71.7492 144.104 72.1117 141.573 72.1117ZM153.238 71.1296L154.201 61.9052H164.5L163.889 71.1296H153.238Z" fill="url(#paint1_linear_1084_44)"/>
    <path d="M189.544 71.1296H179.35L179.959 61.9403H189.766C192.578 61.9403 194.737 61.1803 196.244 59.6605C197.751 58.1406 198.598 55.966 198.785 53.1367L199.38 44.1579C199.567 41.3286 199.008 39.1657 197.701 37.6692C196.395 36.1494 194.337 35.3894 191.525 35.3894H181.683L182.284 26.3053H192.513C198.911 26.3053 203.589 27.8486 206.548 30.9351C209.532 33.9982 210.834 38.4058 210.452 44.1579L209.855 53.1718C209.473 58.9473 207.597 63.39 204.227 66.4999C200.859 69.5863 195.965 71.1296 189.544 71.1296ZM182.479 71.1296H171.652L174.621 26.3053H185.448L182.479 71.1296Z" fill="url(#paint2_linear_1084_44)"/>
    <path d="M226.141 71.1296H215.384L218.354 26.3053H229.11L226.141 71.1296ZM246.844 71.1296H218.583L219.19 61.9753H247.451L246.844 71.1296ZM247.216 52.786H222.892L223.475 43.9825H247.799L247.216 52.786ZM248.898 35.3543H220.918L221.517 26.3053H249.497L248.898 35.3543Z" fill="url(#paint3_linear_1084_44)"/>
    <path d="M260.148 71.8311C258.695 71.8311 257.25 71.7142 255.812 71.4803C254.375 71.2465 253.085 70.9075 251.943 70.4632L251.748 61.2037C252.801 61.5778 253.86 61.8701 254.924 62.0806C255.988 62.291 257.082 62.3962 258.207 62.3962C260.785 62.3962 262.555 61.8467 263.518 60.7478C264.483 59.6254 265.042 57.9068 265.196 55.5919L267.136 26.3053H278.173L276.189 56.2583C275.85 61.3791 274.444 65.2606 271.973 67.9028C269.503 70.5216 265.561 71.8311 260.148 71.8311Z" fill="url(#paint4_linear_1084_44)"/>
    <path d="M301.179 72.2169C294.641 72.2169 289.84 70.5801 286.777 67.3066C283.736 64.033 282.42 59.3214 282.827 53.1718L283.392 44.6489C283.801 38.4759 285.756 33.7176 289.258 30.3739C292.786 27.0068 297.795 25.3232 304.286 25.3232C310.777 25.3232 315.551 27.0068 318.609 30.3739C321.692 33.7176 323.029 38.4759 322.62 44.6489L322.055 53.1718C321.648 59.3214 319.695 64.033 316.198 67.3066C312.7 70.5801 307.694 72.2169 301.179 72.2169ZM301.779 63.1678C304.614 63.1678 306.778 62.3378 308.271 60.6776C309.764 59.0174 310.609 56.6909 310.807 53.6979L311.444 44.0877C311.651 40.9545 311.12 38.5461 309.849 36.8625C308.578 35.179 306.524 34.3372 303.689 34.3372C300.853 34.3372 298.688 35.179 297.194 36.8625C295.7 38.5461 294.849 40.9545 294.642 44.0877L294.005 53.6979C293.807 56.6909 294.344 59.0174 295.617 60.6776C296.889 62.3378 298.943 63.1678 301.779 63.1678Z" fill="url(#paint5_linear_1084_44)"/>
    <path d="M348.653 71.1296H335.472L336.046 62.4664H347.891C350 62.4664 351.526 62.0689 352.47 61.2739C353.436 60.4789 353.972 59.2863 354.078 57.6963L354.117 57.1001C354.222 55.5101 353.844 54.3176 352.983 53.5226C352.147 52.7042 350.639 52.295 348.46 52.295H336.544L337.073 44.2982H348.708C350.747 44.2982 352.214 43.9007 353.111 43.1056C354.007 42.3106 354.506 41.1532 354.606 39.6333L354.629 39.2826C354.729 37.7861 354.381 36.6638 353.587 35.9155C352.795 35.1439 351.379 34.7581 349.34 34.7581H337.67L338.23 26.3053H351.06C356.497 26.3053 360.338 27.2172 362.584 29.0411C364.832 30.8415 365.838 33.5071 365.605 37.0379L365.57 37.564C365.393 40.2296 364.553 42.299 363.049 43.7721C361.569 45.2451 359.335 46.0752 356.346 46.2623L356.321 46.6481C359.596 47.0924 361.936 48.203 363.341 49.9801C364.747 51.7572 365.343 54.2474 365.131 57.4508L365.068 58.3978C364.79 62.6067 363.337 65.7867 360.71 67.9379C358.109 70.0657 354.09 71.1296 348.653 71.1296ZM338.178 71.1296H327.633L330.602 26.3053H341.148L338.178 71.1296Z" fill="url(#paint6_linear_1084_44)"/>
    <defs>
      <linearGradient id="paint0_linear_1084_44" x1="43.4795" y1="-36.2207" x2="57.0605" y2="76.2418" gradientUnits="userSpaceOnUse">
        <stop stop-color="#ED6868"/>
        <stop offset="1" stop-color="#C63939"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
      <linearGradient id="paint2_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
      <linearGradient id="paint3_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
      <linearGradient id="paint4_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
      <linearGradient id="paint5_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
      <linearGradient id="paint6_linear_1084_44" x1="235.952" y1="7.99138" x2="237.245" y2="62.4736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#585858"/>
        <stop offset="1" stop-color="#444444"/>
      </linearGradient>
    </defs>
  </svg>


</template>
<script>
export default  {
  name: 'IconLogo',
  props: {width: {default: 24}}
}
</script>