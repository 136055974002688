<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L6 5L12 0V2L6 7L0 2V0Z" />
  </svg>
</template>

<script>
export default {
  name: "SelectIndicator",
};
</script>

<style scoped lang="less">
.select_indicator {
  svg {
    fill: rgba(0, 0, 0, 0.82);
  }
}
</style>
