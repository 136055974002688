<template>
  <div class="card" :style="{ 'margin-bottom': mb + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    mb: { default: 0 },
  },
};
</script>

<style scoped lang="less">
.card {
  background: white;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 25px;
}
</style>
