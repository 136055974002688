<template>
  <div class="client_card " :class="{ client_card_info: show_info }">
    <div v-if="!show_info" class="client_card_main">
      <div>
        <button
          class="close_client_chat"
          type="secondary"
          size="sm"
          @click="$router.push('/chats')"
        >
          <i class="ni ni-fat-remove"></i>
        </button>
        <div class="client_card_padding">
          <div class="client_name">{{ info.name }}</div>
          <div class="client_platform">{{ info.platform }}</div>
          <div v-if="info.phone">{{ info.phone }}</div>
        </div>
        <div
          class="card"
          style="padding: 15px; margin-bottom: 10px"
          v-for="(r, $ir) in info.responses"
          :key="`s` + $ir"
        >
          <div
            class="card-header border-0"
            style="padding: 0; margin-bottom: 10px"
          >
            <div class="row align-items-center">
              <div class="col">
                <h3 class="mb-0">{{ r.vac_title }}</h3>
                {{ r.city }}
              </div>
            </div>
          </div>
          <div>
            <multiselect
              tag-placeholder=""
              placeholder="Статус"
              label="title"
              track-by="id"
              :options="internal_statuses"
              v-model="r.internal_status"
              @select="event => changeStatus(r.id, event)"
              style="margin-bottom: 10px;"
              :show-labels="false"
            ></multiselect>
            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <span v-if="r.status_registration" style="font-size: 12px"
                  >Я: <strong>{{ r.status_registration }}</strong></span
                >
              </div>
              <div class=" ">
                <base-button
                  @click="$set(r, 'more_info', !r.more_info)"
                  type="secondary"
                  size="sm"
                  >...</base-button
                >
              </div>
            </div>

            <div v-if="r.more_info" style="margin-top: 10px; font-size: 13px">
              <strong>Ссылка на отклик:</strong><br />
              {{ r.url }}

              <br />
              <br />
              <base-button
                size="sm"
                type="secondary"
                @click="changeRegStatus(r.id, 'бездействие_не_слать')"
                >Откатить статус</base-button
              >
            </div>
            <div v-if="r.virtual">
              <strong>Виртуалка:</strong><br />
              <div class="row">
                <div class="col">
                  <a :href="r.virtual_link" target="_blank">Открыть</a>
                </div>
                <div class="col">
                  <base-button
                    style="margin-left: 20px"
                    size="sm"
                    @click="$set(r, 'close_virtual', !r.close_virtual)"
                    >x</base-button
                  >
                  <base-button
                    v-if="r.close_virtual"
                    @click="closeVirtual(r)"
                    type="warning"
                    >Точно закрыть</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="client_card_padding d-flex justify-content-between">
          <div>
            <base-button
              style="margin-top: 10px"
              size="sm"
              type="secondary"
              @click="showInfo"
              >Информация</base-button
            >
          </div>
          <div
            style="padding-left: 10px; flex: 1"
            v-if="$store.state.role === 'admin'"
          >
            <base-button
              style="margin-top: 10px; width: 100%"
              size="sm"
              type="secondary"
              @click="show_clicks = !show_clicks"
              >Клики</base-button
            >
          </div>
        </div>

        <div
          v-if="show_clicks"
          class="client_card_box client_info client_card_padding"
          v-for="(c, $i) in info.clicks"
          :key="$i"
        >
          {{ c.source }}
          <badge
            size="sm"
            type="success"
            v-if="c.away"
            style="margin-left: 15px"
            >перешел</badge
          >
        </div>
        <div class="">
          <div v-if="scheduled.length">
            <div class="client_card_padding" style="padding-bottom: 5px">
              <strong>Запланированные</strong>
            </div>
            <div
              v-for="(m, $i) in scheduled"
              :title="m.text"
              :key="$i"
              class="client_card_box client_card_scheduled_box"
              :class="{ client_card_scheduled_box_schedule: m.notify }"
            >
              <div class="client_card_scheduled_box_text">
                {{ m.text }}<strong v-if="!m.text">Напоминание о чате.</strong>
              </div>
              <small style="font-size: 12px">{{ m.date }}</small>
              <button class="my_btn" @click="deleteScheduled(m)">x</button>
            </div>
          </div>
          <br />
        </div>
      </div>
      <div class="client_card_main_bottom">
        <base-button
          type="secondary"
          v-if="info.status === 'active'"
          @click="close"
          ><div class="client_card_main_bottom_circle"></div>
          Закрыть</base-button
        >

        <base-button type="secondary" v-else @click="close"
          ><div
            class="client_card_main_bottom_circle client_card_main_bottom_circle_green"
          ></div>
          Открыть</base-button
        >
      </div>
    </div>
    <div v-else class="client_card_info_wrapper client_card_padding">
      <button class="circle_button_info" @click="closeInfo">
        <i class="ni ni-bold-left"></i>
      </button>
      <div class="client_card_info_box">
        <base-button
          size="sm"
          type="secondary"
          v-if="click_more.message"
          @click="$bus.$emit('suggest_vac_message', click_more.message)"
          >Предложить</base-button
        >
        <div class="mb-3">{{ click_more.vac_url }}</div>
        <div class="mb-3">{{ click_more.external_url }}</div>
        <div>
          <strong>Адрес хаба:</strong>
          <div class="client_card_info_text" v-html="click_info.address"></div>
        </div>
        <div v-for="(a, $i) in click_info.info" :key="$i">
          <strong>{{ a.title }}</strong>
          <div class="client_card_info_text" v-html="a.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ClientCard",
  data() {
    return {
      info: { clicks: [], responses: [] },
      scheduled: [],
      show_info: false,
      click_info: null,
      click_more: {},
      show_clicks: true,
      internal_statuses: []
    };
  },
  methods: {
    load(after_close = false) {
      axios
        .post("messages/get-user-info", { id: this.$route.params.id })
        .then(res => {
          console.log("info", res);
          this.info = res.data.info;
          this.scheduled = res.data.scheduled;
          this.internal_statuses = res.data.internal_statuses;

          this.$store.commit("setChatsName", this.info.first_name);

          if (after_close)
            this.$bus.$emit("update_chat_list", {
              id: this.$route.params.id,
              status: this.info.status
            });
        });
    },
    changeStatus(c, e) {
      console.log(c, e);
      axios
        .post("responses/change-internal-status", { id: c, status: e.id })
        .then(res => {
          this.$notify("Статус изменен.");
          this.load();
        });
    },
    changeRegStatus(c, status) {
      axios
        .post("responses/change-reg-status", { id: c, status: status })
        .then(res => {
          this.$notify("Статус изменен.");
          this.load();
        });
    },
    closeVirtual(r) {
      console.log(r.id);
      axios
        .post("https://panel.jobinc.ru/gde/close-virtual", { id: r.id })
        .then(res => {
          this.$notify("Закрыто");
          this.load();
        });
    },
    close() {
      axios.post("messages/close", { id: this.$route.params.id }).then(res => {
        this.$notify("Готово.");
        this.load(true);
      });
    },
    showInfo(c) {
      axios
        .post("messages/get-vac-info", {
          vac_id: this.info.responses[0].vac_id,
          city_id: this.info.responses[0].city_id
        })
        .then(res => {
          this.click_info = res.data.info;
          this.click_more = c;
        });
      this.show_info = true;
    },
    closeInfo() {
      this.show_info = false;
      this.click_info = null;
      this.click_more = {};
    },
    deleteScheduled(m) {
      axios.post("messages/delete", { id: m.id }).then(res => {
        this.load();
        this.$notify("Удалено.");
      });
    }
  },
  mounted() {
    this.load();

    this.$bus.$on("update_client_info", () => {
      this.load();
    });
  }
};
</script>

<style scoped lang="less">
.client_card {
  height: 100%;
  //box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  z-index: 1;
  border-left: 1px solid;
  border-color: #f1f1f1;
  width: 250px;
  padding: 5px;
  overflow-y: auto;
}

.client_card_info_box {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  word-break: break-word;
}

.client_card_info_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.client_card_info_text {
  white-space: break-spaces;
  margin-bottom: 15px;
}

.client_card_info {
  width: 450px;
}

.client_img {
  img {
    width: 100%;
  }
  margin-bottom: 10px;
}

.client_name {
  font-weight: 600;
  font-size: 17px;
}

.client_platform {
  font-weight: 600;
  font-size: 14px;
}

.client_sub {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
}

.client_info {
  margin-bottom: 20px;
}

.close_client_chat {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
  background: transparent;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.client_card_scheduled_box_text {
  font-size: 14px;
}

.client_card_padding {
  padding: 15px;
}

.client_card_box {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 15px;
}

.client_card_scheduled_box {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.client_card_main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.client_card_main_bottom {
  padding: 13px;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.client_card_main_bottom_circle {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: #f5365c;
  margin-right: 13px;
}

.client_card_main_bottom_circle_green {
  background: #00dea2;
}

.client_card_scheduled_box_schedule {
  background: #fffece;
}
</style>
