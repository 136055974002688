<template>
  <login-box>
    <base-input placeholder="Email" v-model="email" :mb="15"> </base-input>

    <base-input
      placeholder="Пароль"
      type="password"
      v-model="password"
      :mb="30"
      @keyup.enter="reg"
    >
    </base-input>

    <ErrorsBox v-if="errors.length" :errors="errors"></ErrorsBox>

    <base-button @click="reg" :loading="loading"
      >Зарегистрироваться</base-button
    >

    <div style="margin-top: 40px">
      <router-link to="/login">Войти</router-link>
    </div>
  </login-box>
</template>

<script>
import LoginBox from "@/components/LoginBox";
import ErrorsBox from "@/components/ErrorsBox";
export default {
  name: "Registration",
  components: { ErrorsBox, LoginBox },
  data() {
    return {
      email: "",
      password: "",
      errors: [],
      loading: false,
    };
  },
  methods: {
    reg() {
      this.loading = true;
      const { email, password } = this;
      this.$store
        .dispatch("reg", { User: { email: email, password: password } })
        .then((res) => {
          this.$router.push({ name: "dashboard" });
        })
        .catch((res) => {
          this.errors = res.data.errors;
          this.loading = false;
        });
    },
  },
  metaInfo() {
    return {
      title: "Вход",
    };
  },
};
</script>

<style scoped></style>
