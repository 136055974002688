<template>
  <div class="layout_base"><RouterView></RouterView></div>
</template>

<script>
export default {
  name: "Base",
};
</script>

<style scoped lang="less">
@import "../assets/vars";

.layout_base {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @color_bg;
}
</style>
