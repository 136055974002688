<template>
  <Body>
    <page-header title="Вакансии">
      <template v-slot:right>
        <base-button @click="loadVacs" :loading="vac_loading"
          >Обновить вакансии</base-button
        >
      </template>
    </page-header>
    <div class="vacancies_wrapper">
      <div class="vacancies_top_wrapper">
        <div
          class="vacancies_top_th"
          :style="[
            { 'margin-right': scrollbarWidth + 'px' },
            { 'padding-right': 50 - scrollbarWidth + 'px' },
          ]"
        >
          <div class="vacancies_item_width vacancies_item_width_checkbox">
            <CheckBox v-model="select_all" @change="selectAll"></CheckBox>
          </div>
          <div class="vacancies_item_width vacancies_item_width_title">
            <base-input
              v-model="filter.title"
              placeholder="Название вакансии"
            ></base-input>
          </div>
          <div class="vacancies_item_width vacancies_item_width_vac_id">
            <base-input
              v-model="filter.remote_id"
              placeholder="ID вакансии"
            ></base-input>
          </div>
          <div class="vacancies_item_width vacancies_item_width_city">
            <v-select
              class="my_select"
              v-model="filter.city"
              :options="cities"
              placeholder="Город"
            ></v-select>
          </div>
          <div class="vacancies_item_width vacancies_item_width_reply">
            <v-select
              class="my_select"
              :options="auto_replies"
              placeholder="Чат-бот"
              label="name"
              v-model="filter.reply"
              :reduce="(name) => name.id"
            ></v-select>
          </div>
          <div class="fake_scroll_el"></div>
        </div>
      </div>
      <div class="vacancies_items_wrapper">
        <div class="vacancies_empty_box" v-if="!items.length && vacs_loaded">
          <div>Здесь пока пусто.</div>
          <base-button @click="loadVacs" :loading="vac_loading"
            >Загрузить вакансии</base-button
          >
        </div>
        <div v-else class="vacancies_items_wrapper_rec">
          <RecycleScroller
            class="scroller"
            :items="filteredItems"
            :item-size="70"
            key-field="id"
            v-slot="{ item }"
          >
            <div
              class="vacancies_item"
              :style="{ 'padding-right': rowPadding }"
              @click="item.checked = !item.checked"
            >
              <div
                class="vacancies_item_width vacancies_item_width_checkbox"
                @click.prevent=""
              >
                <CheckBox v-model="item.checked"></CheckBox>
              </div>
              <div class="vacancies_item_width vacancies_item_width_title">
                {{ item.title }}
                <a
                  class="vacancies_item_ext_link"
                  :href="item.url"
                  target="_blank"
                  ><i class="fas fa-md fa-external-link-alt"></i
                ></a>
              </div>
              <div class="vacancies_item_width vacancies_item_width_vac_id">
                {{ item.remote_id }}
              </div>
              <div class="vacancies_item_width vacancies_item_width_city">
                {{ item.city }}
              </div>
              <div class="vacancies_item_width vacancies_item_width_reply">
                <div v-if="item.template_id">
                  <div>{{ item.template.name }}</div>
                  <badge v-if="item.template.is_default">По умолчанию</badge>
                </div>
              </div>
            </div>
          </RecycleScroller>
        </div>

        <selected-box
          v-if="selectedItems.length"
          :count="selectedItems.length"
          :scrollbar-width="scrollbarWidth"
          @bind-reply="$refs.popup.open()"
          @unbind-reply="$refs.unbind_popup.open()"
        ></selected-box>
      </div>
      <div class="vacancies_items_bottom" v-if="items.length && vacs_loaded">
        Вакансий: <strong>{{ filteredItems.length }}</strong>
      </div>

      <div v-if="loading" class="page_loader">
        <loader></loader>
      </div>
    </div>

    <popup ref="popup">
      <div style="margin-bottom: 20px">
        Привязать вакансии (<strong>{{ selectedItems.length }}</strong> шт.) к
        чат-боту:
      </div>
      <v-select
        class="my_select"
        style="margin-bottom: 20px"
        :options="auto_replies"
        label="name"
        v-model="selected_auto_reply"
        :reduce="(name) => name.id"
      ></v-select>
      <base-button
        @click="bindVacsToReply"
        :active="selectedItems.length && selected_auto_reply"
        :loading="bind_reply_loading"
        >Привязать</base-button
      >
    </popup>
    <popup ref="unbind_popup">
      <div style="margin-bottom: 20px">
        Отвязать вакансии (<strong>{{ selectedItems.length }}</strong> шт.) от
        чат-ботов:
      </div>
      <base-button
        type="red"
        @click="unbindVacsReply"
        :loading="bind_reply_loading"
        >Точно отвязать</base-button
      >
    </popup>
  </Body>
</template>

<script>
import Body from "@/components/Body";
import PageHeader from "@/components/PageHeader";
import BaseButton from "@/components/ui/BaseButton";
import SelectedBox from "@/components/SelectedBox";
import axios from "axios";
import Popup from "@/components/Popup";
import Badge from "@/components/ui/Badge";
import Loader from "@/components/ui/Loader";
export default {
  name: "Vacancies",
  components: {
    Loader,
    Badge,
    Popup,
    SelectedBox,
    BaseButton,
    PageHeader,
    Body,
  },
  data() {
    return {
      items: [],
      select_all: false,
      scrollbarWidth: 0,
      filter: {
        title: null,
        remote_id: null,
        city: null,
        reply: null,
      },
      cities: [],
      vac_titles: [],
      vac_loading: false,
      vacs_loaded: false,
      auto_replies: [],
      selected_auto_reply: null,
      bind_reply_loading: false,
      loading: false,
    };
  },
  computed: {
    filteredItems() {
      let items = this.items;
      const { filter } = this;
      console.log("dsad");

      if (
        filter.title === null &&
        filter.remote_id === null &&
        filter.city === null &&
        filter.reply === null
      )
        return items;

      if (filter.title) {
        const lowerCaseSearch = filter.title.toLowerCase();
        items = items.filter((i) =>
          i.title.toLowerCase().includes(lowerCaseSearch)
        );
      }

      if (filter.city) {
        const lowerCaseSearch = filter.city.toLowerCase();
        items = items.filter((i) => i.city.toLowerCase() === lowerCaseSearch);
      }

      if (filter.remote_id) {
        items = items.filter((i) => i.remote_id === parseInt(filter.remote_id));
      }

      if (filter.reply) {
        items = items.filter((i) => i.template_id === parseInt(filter.reply));
      }

      return items;
    },
    selectedItems() {
      return this.filteredItems.filter((i) => i.checked);
    },
    rowPadding() {
      return 50 - this.scrollbarWidth + "px";
    },
  },
  methods: {
    bindVacsToReply() {
      this.bind_reply_loading = true;

      let vacs = this.selectedItems.map((i) => i.id);

      axios
        .post("vacancies/set-auto-reply", {
          ids: vacs,
          reply_id: this.selected_auto_reply,
        })
        .then((res) => {
          if (this.selected_auto_reply !== null)
            this.$notify("Вакансии привязаны!");
          else this.$notify("Вакансии отвязаны!");
          this.load();
          this.$refs.popup.hide();
          this.$refs.unbind_popup.hide();
          this.bind_reply_loading = false;
        });
    },
    unbindVacsReply() {
      this.selected_auto_reply = null;
      this.bindVacsToReply();
    },
    selectAll() {
      let items = [...this.items];

      items.map((el) => {
        el.checked = this.select_all;
      });

      this.items = items;
    },
    load() {
      this.loading = true;
      axios.post("vacancies/get-all").then((res) => {
        this.items = res.data.vacancies;
        this.getScrollBarWidth();
        this.getArrays();
        this.vacs_loaded = true;
        this.loading = false;
      });
    },
    loadVacs() {
      this.vac_loading = true;
      axios.post("vacancies/get-remote").then((res) => {
        this.load();
        this.$notify("Вакансии загружены!");
        this.vac_loading = false;
      });
    },
    getArrays() {
      this.cities = this.items
        .map((item) => item.city)
        .filter((value, index, self) => self.indexOf(value) === index);

      // this.vac_titles = this.items
      //   .map((item) => item.title)
      //   .filter((value, index, self) => self.indexOf(value) === index);

      axios.post("auto-replies/get-all").then((res) => {
        this.auto_replies = res.data.templates;
      });
    },
    getScrollBarWidth() {
      let scrollDiv = document.createElement("div");
      scrollDiv.className = "scrollbar-measure";
      document.body.appendChild(scrollDiv);
      this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    },
  },
  mounted() {
    this.load();
  },
  metaInfo() {
    return {
      title: "Вакансии",
    };
  },
};
</script>

<style scoped lang="less">
@import "../assets/vars";
.vacancies_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.vacancies_top_wrapper {
}

.vacancies_items_wrapper {
  flex: 1;
  position: relative;
}

.vacancies_items_wrapper_rec {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
}

.vacancies_items_bottom {
  border-top: 1px solid @color_lines_gray;
  padding: 3px 50px;
  font-size: 13px;
  color: @color_gray_text;
}

.scroller {
  height: 100%;
  overflow-y: scroll !important;
}

.vacancies_item {
  height: 70px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid @color_lines_gray;
  overflow: hidden;
}

.vacancies_item:hover {
  background: @color_bg;
}

.vacancies_top_toolbar {
  padding: 10px 50px;
  background: white;
  border-bottom: 3px solid @color_lines_gray;
}

.vacancies_top_th {
  background: @color_bg;
  border-bottom: 1px solid @color_lines_gray;
  padding: 7px 50px;
  display: flex;
}

.vacancies_item_width {
  padding-right: 20px;
}

.vacancies_item {
  .vacancies_item_width {
    padding-left: 14px;
  }

  .vacancies_item_width_checkbox {
    padding-left: 0;
  }
}

.vacancies_item_width_checkbox {
  width: 4%;
  display: flex;
  align-items: center;
}

.vacancies_item_width_title {
  width: 20%;
}

.vacancies_item_width_city {
  width: 28%;
}
.vacancies_item_width_vac_id {
  width: 20%;
}

.vacancies_item_width_reply {
  width: 28%;
  padding-right: 0;
}

.vacancies_empty_box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.vacancies_item_ext_link {
  font-size: 14px;
  color: #c9c9c9;
}

.vacancies_item_ext_link:hover {
  color: #000000;
}

.page_loader {
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
}
</style>
