import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Main from "@/layouts/Main";
import Vacancies from "@/views/Vacancies";
import Dashboard from "@/views/Dashboard";
import Base from "@/layouts/Base";
import Login from "@/views/Login";
import Registration from "@/views/Registration";
import Accounts from "@/views/Accounts";
import EditAccount from "@/views/accounts/EditAccount";
import AutoReplies from "@/views/AutoReplies";
import EditAutoReply from "@/views/auto-replies/EditAutoReply";
import AvitoCallback from "@/views/accounts/AvitoCallback";
import Settings from "@/views/Settings";
import Chats from "@/views/Chats.vue";
import ChatView from "@/components/chat/ChatView.vue";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    routes: [
        {
            path: '/',
            component: Main,
            redirect: '/dashboard',
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    component: Dashboard
                },
                {
                    path: '/accounts',
                    name: 'accounts',
                    component: Accounts
                },
                {
                    path: '/accounts/avito-callback',
                    name: 'avito_callback',
                    component: AvitoCallback
                },
                {
                    path: '/accounts/:id',
                    name: 'accounts_edit',
                    component: EditAccount
                },
                {
                    path: '/vacancies',
                    name: 'vacancies',
                    component: Vacancies
                },
                {
                    path: '/auto-replies',
                    name: 'auto_replies',
                    component: AutoReplies
                },
                {
                    path: '/auto-replies/:id',
                    name: 'auto_replies_edit',
                    component: EditAutoReply
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: Settings
                },
                {
                    path: '/chats',
                    name: 'chats',
                    component: Chats,
                    children: [
                        {
                            path: ':id',
                            name: 'chats',
                            component: ChatView,
                            meta: {
                                chat: true,
                                hideFooter: true,
                                hideNav: true
                            }
                        }
                    ]
                },
            ]
        },
        {
            path: '/',
            component: Base,
            redirect: '/login',
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: Login
                },
                {
                    path: '/registration',
                    name: 'registration',
                    component: Registration
                }
            ]
        }
    ]
});
