<template>
  <svg :width="width" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512">
    <g stroke-width="2.00" fill="none" stroke-linecap="butt">
      <path stroke="#414074" vector-effect="non-scaling-stroke" d="
  M 211.81 0.00
  C 206.25 8.43 206.95 11.41 212.54 19.50"
      />
      <path stroke="#4d4c82" vector-effect="non-scaling-stroke" d="
  M 212.54 19.50
  C 201.28 20.52 200.67 34.94 211.84 36.42"
      />
      <path stroke="#414074" vector-effect="non-scaling-stroke" d="
  M 211.84 36.42
  Q 209.34 42.44 211.34 48.76
  A 0.69 0.69 0.0 0 0 212.00 49.25
  Q 255.22 49.26 298.44 49.23
  C 305.06 49.23 303.76 41.07 304.99 37.51
  C 310.90 20.33 329.66 24.15 343.78 23.99
  Q 353.32 23.89 358.09 25.23
  C 372.72 29.34 378.82 41.04 378.78 55.66
  Q 378.73 77.43 378.75 99.21"
      />
      <path stroke="#9d8b54" vector-effect="non-scaling-stroke" d="
  M 378.75 99.21
  L 368.00 99.04"
      />
      <path stroke="#9d764d" vector-effect="non-scaling-stroke" d="
  M 368.00 99.04
  L 362.00 98.96"
      />
      <path stroke="#9294b4" vector-effect="non-scaling-stroke" d="
  M 362.00 98.96
  Q 362.02 77.55 361.98 56.14
  C 361.95 44.82 358.34 40.98 346.65 40.99
  Q 334.63 41.00 322.71 41.01
  A 1.52 1.52 0.0 0 0 321.19 42.42
  C 320.13 57.97 310.42 66.05 295.56 66.13
  Q 251.12 66.38 206.79 66.20"
      />
      <path stroke="#343361" vector-effect="non-scaling-stroke" d="
  M 206.79 66.20
  Q 206.54 63.58 205.97 61.01
  C 205.06 56.93 207.46 53.32 210.22 50.60
  A 0.55 0.54 47.0 0 0 210.24 49.85
  C 207.97 47.31 191.66 51.39 187.23 47.99
  C 183.77 45.34 185.00 41.55 184.01 38.29
  C 180.99 28.36 173.67 24.34 163.69 24.11
  Q 152.72 23.85 141.75 23.97
  Q 133.81 24.05 131.57 24.75
  Q 109.95 31.47 109.97 53.75
  Q 110.02 113.92 110.00 174.09
  C 110.00 175.24 110.89 187.91 110.33 188.24
  A 0.59 0.59 0.0 0 0 110.04 188.75
  L 110.00 199.96"
      />
      <path stroke="#2b71a8" vector-effect="non-scaling-stroke" d="
  M 110.00 199.96
  L 86.00 199.96"
      />
      <path stroke="#5852b1" vector-effect="non-scaling-stroke" d="
  M 86.00 199.96
  Q 86.00 146.23 86.00 92.50
  Q 86.00 91.75 85.36 91.44"
      />
      <path stroke="#5e59bc" vector-effect="non-scaling-stroke" d="
  M 403.00 70.96
  Q 402.38 84.96 403.00 98.96"
      />
      <path stroke="#a39260" vector-effect="non-scaling-stroke" d="
  M 403.00 98.96
  L 378.75 99.21"
      />
      <path stroke="#53538d" vector-effect="non-scaling-stroke" d="
  M 211.84 36.42
  Q 263.20 36.72 276.94 36.43
  C 285.84 36.23 288.14 23.52 279.66 20.21
  Q 277.85 19.50 275.90 19.50
  L 212.54 19.50"
      />
      <path stroke="#8b8eac" vector-effect="non-scaling-stroke" d="
  M 206.79 66.20
  L 206.00 66.29"
      />
      <path stroke="#8085aa" vector-effect="non-scaling-stroke" d="
  M 206.00 66.29
  Q 198.01 66.42 190.03 66.05
  C 176.83 65.45 168.40 56.01 167.51 42.85
  Q 167.39 41.01 165.55 41.01
  Q 153.28 40.97 141.04 41.02
  C 129.36 41.07 126.98 46.53 126.99 57.65
  Q 127.04 128.83 126.96 200.00"
      />
      <path stroke="#3878ab" vector-effect="non-scaling-stroke" d="
  M 126.96 200.00
  L 122.10 200.37"
      />
      <path stroke="#246ba1" vector-effect="non-scaling-stroke" d="
  M 122.10 200.37
  L 110.00 199.96"
      />
      <path stroke="#dee6fd" vector-effect="non-scaling-stroke" d="
  M 206.00 66.29
  C 205.68 66.86 205.41 67.41 204.88 67.84
  Q 197.59 73.76 197.54 83.06
  Q 197.40 110.35 197.54 183.44
  Q 197.55 192.24 204.67 198.36
  Q 205.05 198.68 205.27 199.11
  L 205.75 200.04"
      />
      <path stroke="#8bd0fc" vector-effect="non-scaling-stroke" d="
  M 205.75 200.04
  Q 166.35 200.54 126.96 200.00"
      />
      <path stroke="#8bd0fc" vector-effect="non-scaling-stroke" d="
  M 175.36 83.78
  A 7.92 7.92 0.0 0 0 167.44 75.86
  L 152.10 75.86
  A 7.92 7.92 0.0 0 0 144.18 83.78
  L 144.18 84.66
  A 7.92 7.92 0.0 0 0 152.10 92.58
  L 167.44 92.58
  A 7.92 7.92 0.0 0 0 175.36 84.66
  L 175.36 83.78"
      />
      <path stroke="#f4d199" vector-effect="non-scaling-stroke" d="
  M 362.00 98.96
  Q 326.12 98.92 290.24 99.15
  C 280.37 99.21 272.29 103.67 268.86 113.20
  Q 267.49 117.02 267.49 124.45
  Q 267.52 166.79 267.48 209.12
  Q 267.47 216.79 268.84 220.71
  C 274.85 237.97 291.85 234.86 306.51 235.01
  C 309.10 235.04 311.26 235.79 312.66 237.84
  Q 314.73 240.87 324.32 255.59
  Q 326.58 259.05 328.88 260.21
  C 335.12 263.36 338.40 258.57 341.82 253.40
  Q 346.02 247.05 350.19 240.61
  C 353.35 235.73 356.17 233.65 361.96 235.25"
      />
      <path stroke="#9294b4" vector-effect="non-scaling-stroke" d="
  M 361.96 235.25
  L 361.96 300.75"
      />
      <path stroke="#e978aa" vector-effect="non-scaling-stroke" d="
  M 361.96 300.75
  Q 327.12 301.21 292.27 300.97
  C 281.50 300.89 272.65 304.38 268.89 315.04
  Q 267.48 319.02 267.49 327.11
  Q 267.51 369.54 267.50 411.97
  C 267.50 422.55 270.49 430.84 280.52 435.08
  C 287.57 438.06 299.01 437.12 307.01 436.96
  Q 310.75 436.88 313.10 440.42
  Q 318.55 448.59 323.92 456.84
  C 326.78 461.23 330.67 464.96 335.82 462.10
  Q 338.20 460.79 341.67 455.46
  Q 347.06 447.17 352.58 438.92
  Q 353.74 437.18 355.83 437.15
  L 362.00 437.04"
      />
      <path stroke="#9294b4" vector-effect="non-scaling-stroke" d="
  M 362.00 437.04
  L 362.25 487.96"
      />
      <path stroke="#dee6fd" vector-effect="non-scaling-stroke" d="
  M 204.79 506.68
  Q 200.85 502.53 198.90 497.35
  Q 197.61 493.91 197.59 487.96
  Q 197.40 422.35 197.54 356.73
  C 197.57 345.33 199.83 343.27 207.29 336.00"
      />
      <path stroke="#96d9fe" vector-effect="non-scaling-stroke" d="
  M 207.29 336.00
  C 214.02 335.61 222.99 336.79 229.08 334.86
  C 243.81 330.20 244.52 319.12 244.51 304.40
  Q 244.50 263.85 244.48 223.31
  C 244.48 209.18 235.33 200.04 221.42 200.24
  Q 213.57 200.35 205.75 200.04"
      />
      <path stroke="#baa49d" vector-effect="non-scaling-stroke" d="
  M 434.25 98.96
  Q 418.63 99.69 403.00 98.96"
      />
      <path stroke="#478af1" vector-effect="non-scaling-stroke" d="
  M 86.00 199.96
  Q 66.53 200.04 47.01 199.99
  Q 38.99 199.96 35.11 201.35
  C 27.09 204.21 21.13 211.72 21.09 220.57
  Q 20.93 265.55 21.00 310.54
  Q 21.01 318.30 22.42 322.16
  Q 25.76 331.30 35.27 334.70
  C 41.83 337.05 62.49 333.47 65.68 338.30
  Q 71.79 347.59 77.92 356.81
  Q 81.22 361.76 86.00 362.29"
      />
      <path stroke="#5852b1" vector-effect="non-scaling-stroke" d="
  M 86.00 362.29
  L 85.94 442.16
  A 0.57 0.57 0.0 0 1 85.72 442.61
  L 85.50 442.79"
      />
      <path stroke="#ffc839" vector-effect="non-scaling-stroke" d="
  M 368.00 99.04
  L 367.37 99.81
  Q 367.06 100.19 366.60 100.38
  Q 352.52 106.10 352.45 121.76
  Q 352.44 124.35 352.25 126.96"
      />
      <path stroke="#fed68f" vector-effect="non-scaling-stroke" d="
  M 352.25 126.96
  Q 330.01 127.04 307.74 126.99
  C 296.45 126.97 295.36 143.89 308.78 143.77
  Q 330.64 143.58 352.50 143.96"
      />
      <path stroke="#ffc839" vector-effect="non-scaling-stroke" d="
  M 352.50 143.96
  Q 351.88 151.21 352.50 158.46"
      />
      <path stroke="#fed06e" vector-effect="non-scaling-stroke" d="
  M 352.50 158.46
  Q 330.76 158.43 309.02 158.62
  C 295.35 158.74 296.37 175.53 307.75 175.51
  Q 330.00 175.46 352.25 175.54"
      />
      <path stroke="#ffc839" vector-effect="non-scaling-stroke" d="
  M 352.25 175.54
  L 352.50 190.21"
      />
      <path stroke="#fed06e" vector-effect="non-scaling-stroke" d="
  M 352.50 190.21
  Q 330.13 190.29 307.75 190.24
  C 296.60 190.22 295.69 206.71 307.63 206.95
  Q 329.93 207.41 352.25 207.29"
      />
      <path stroke="#ffc839" vector-effect="non-scaling-stroke" d="
  M 352.25 207.29
  C 352.00 219.10 354.16 228.82 366.26 233.46
  A 0.77 0.76 10.7 0 1 366.75 234.17
  L 366.75 234.96"
      />
      <path stroke="#9d764d" vector-effect="non-scaling-stroke" d="
  M 366.75 234.96
  L 361.96 235.25"
      />
      <path stroke="#baa49d" vector-effect="non-scaling-stroke" d="
  M 491.29 199.25
  C 490.82 205.62 491.80 213.37 490.17 219.04
  Q 485.57 235.06 467.50 235.02
  Q 435.25 234.95 403.00 235.04"
      />
      <path stroke="#a39260" vector-effect="non-scaling-stroke" d="
  M 403.00 235.04
  L 378.75 235.04"
      />
      <path stroke="#9d8b54" vector-effect="non-scaling-stroke" d="
  M 378.75 235.04
  L 366.75 234.96"
      />
      <path stroke="#fee575" vector-effect="non-scaling-stroke" d="
  M 352.25 207.29
  Q 400.54 207.24 448.80 207.21
  C 463.14 207.21 462.54 190.23 450.75 190.24
  Q 401.62 190.29 352.50 190.21"
      />
      <path stroke="#fee575" vector-effect="non-scaling-stroke" d="
  M 352.25 175.54
  Q 400.40 175.47 448.55 175.50
  Q 453.11 175.50 455.16 174.52
  C 460.73 171.87 460.97 163.23 455.85 160.10
  C 453.16 158.45 448.70 158.48 445.75 158.49
  Q 399.13 158.54 352.50 158.46"
      />
      <path stroke="#feeb96" vector-effect="non-scaling-stroke" d="
  M 352.50 143.96
  Q 362.34 143.59 372.22 143.77
  Q 375.57 143.83 377.22 143.17
  C 384.18 140.41 384.23 130.43 377.24 127.74
  Q 375.28 126.98 370.64 127.00
  Q 361.45 127.03 352.25 126.96"
      />
      <path stroke="#ba56a7" vector-effect="non-scaling-stroke" d="
  M 491.58 334.27
  Q 490.83 329.69 490.88 325.10
  C 490.99 314.60 487.13 306.00 476.99 302.47
  Q 472.63 300.96 461.83 300.98
  Q 432.42 301.05 403.00 300.96"
      />
      <path stroke="#5e59bc" vector-effect="non-scaling-stroke" d="
  M 403.00 300.96
  Q 402.38 268.00 403.00 235.04"
      />
      <path stroke="#2eb6f2" vector-effect="non-scaling-stroke" d="
  M 122.10 200.37
  Q 110.93 203.98 107.05 214.07
  Q 104.70 220.17 106.20 228.00"
      />
      <path stroke="#69cdf4" vector-effect="non-scaling-stroke" d="
  M 106.20 228.00
  Q 84.51 228.01 62.70 227.99
  Q 59.44 227.99 57.87 228.57
  C 51.15 231.04 50.49 240.54 56.89 243.74
  Q 58.96 244.77 63.29 244.76
  Q 84.51 244.71 105.75 244.79"
      />
      <path stroke="#2eb6f2" vector-effect="non-scaling-stroke" d="
  M 105.75 244.79
  L 105.75 259.46"
      />
      <path stroke="#52c5f4" vector-effect="non-scaling-stroke" d="
  M 105.75 259.46
  Q 83.75 259.54 61.75 259.49
  C 54.75 259.47 49.58 266.55 53.86 272.61
  C 57.31 277.48 63.13 276.39 69.31 276.30
  Q 83.24 276.09 105.97 276.53"
      />
      <path stroke="#2eb6f2" vector-effect="non-scaling-stroke" d="
  M 105.97 276.53
  L 105.76 291.17"
      />
      <path stroke="#52c5f4" vector-effect="non-scaling-stroke" d="
  M 105.76 291.17
  Q 84.66 291.34 63.57 291.20
  Q 58.83 291.17 56.65 292.36
  C 50.96 295.47 51.01 304.04 56.99 307.02
  Q 58.95 308.01 63.68 308.00
  Q 84.84 307.97 106.00 308.04"
      />
      <path stroke="#2eb6f2" vector-effect="non-scaling-stroke" d="
  M 106.00 308.04
  Q 105.12 314.84 106.37 319.57
  Q 109.47 331.23 121.66 335.57"
      />
      <path stroke="#246ba1" vector-effect="non-scaling-stroke" d="
  M 121.66 335.57
  L 109.25 336.29"
      />
      <path stroke="#2b71a8" vector-effect="non-scaling-stroke" d="
  M 109.25 336.29
  C 104.52 337.77 95.54 353.98 92.42 358.68
  Q 90.49 361.60 86.00 362.29"
      />
      <path stroke="#8bd0fc" vector-effect="non-scaling-stroke" d="
  M 207.29 336.00
  L 127.43 335.81
  A 0.54 0.53 -79.7 0 0 126.93 336.16
  L 126.79 336.50"
      />
      <path stroke="#3878ab" vector-effect="non-scaling-stroke" d="
  M 126.79 336.50
  L 126.74 336.17
  A 0.42 0.42 0.0 0 0 126.35 335.81
  L 121.66 335.57"
      />
      <path stroke="#66d2fe" vector-effect="non-scaling-stroke" d="
  M 106.00 308.04
  Q 153.70 307.96 201.40 308.01
  Q 206.31 308.01 208.44 307.00
  C 214.24 304.22 214.55 295.87 209.00 292.48
  Q 207.00 291.26 202.64 291.26
  Q 154.19 291.30 105.76 291.17"
      />
      <path stroke="#66d2fe" vector-effect="non-scaling-stroke" d="
  M 105.97 276.53
  Q 153.42 276.48 200.78 276.46
  Q 205.60 276.46 207.51 275.79
  C 216.84 272.49 213.59 259.49 203.75 259.50
  Q 154.75 259.53 105.75 259.46"
      />
      <path stroke="#7ddafe" vector-effect="non-scaling-stroke" d="
  M 105.75 244.79
  Q 115.83 244.72 125.90 244.75
  Q 128.95 244.76 130.51 244.15
  C 137.03 241.59 137.84 232.66 131.75 229.21
  C 127.18 226.61 111.99 227.99 106.20 228.00"
      />
      <path stroke="#414074" vector-effect="non-scaling-stroke" d="
  M 378.75 235.04
  L 378.75 300.96"
      />
      <path stroke="#9d3d5f" vector-effect="non-scaling-stroke" d="
  M 378.75 300.96
  L 368.84 301.37"
      />
      <path stroke="#921c5e" vector-effect="non-scaling-stroke" d="
  M 368.84 301.37
  L 361.96 300.75"
      />
      <path stroke="#a3446a" vector-effect="non-scaling-stroke" d="
  M 403.00 300.96
  L 378.75 300.96"
      />
      <path stroke="#f42155" vector-effect="non-scaling-stroke" d="
  M 368.84 301.37
  C 356.07 305.16 351.59 315.19 352.25 327.71"
      />
      <path stroke="#f24b7f" vector-effect="non-scaling-stroke" d="
  M 352.25 327.71
  Q 330.00 327.79 307.75 327.74
  C 296.42 327.71 295.39 344.50 308.68 344.48
  Q 330.44 344.45 352.21 344.75"
      />
      <path stroke="#f42155" vector-effect="non-scaling-stroke" d="
  M 352.21 344.75
  L 352.00 360.46"
      />
      <path stroke="#f4396d" vector-effect="non-scaling-stroke" d="
  M 352.00 360.46
  Q 331.02 360.51 310.04 360.52
  Q 305.35 360.52 303.24 361.57
  C 297.90 364.22 297.62 372.86 302.62 375.89
  Q 304.84 377.23 309.29 377.23
  Q 330.64 377.23 352.00 377.29"
      />
      <path stroke="#f42155" vector-effect="non-scaling-stroke" d="
  M 352.00 377.29
  L 352.25 391.96"
      />
      <path stroke="#f4396d" vector-effect="non-scaling-stroke" d="
  M 352.25 391.96
  Q 330.57 391.92 308.89 392.14
  C 295.37 392.27 296.21 409.02 308.25 409.00
  Q 330.25 408.97 352.25 409.04"
      />
      <path stroke="#f42155" vector-effect="non-scaling-stroke" d="
  M 352.25 409.04
  Q 352.05 416.62 352.62 419.07
  Q 355.67 432.31 368.65 436.45"
      />
      <path stroke="#921c5e" vector-effect="non-scaling-stroke" d="
  M 368.65 436.45
  L 362.00 437.04"
      />
      <path stroke="#ba56a7" vector-effect="non-scaling-stroke" d="
  M 432.25 437.04
  L 402.75 436.79"
      />
      <path stroke="#a3446a" vector-effect="non-scaling-stroke" d="
  M 402.75 436.79
  L 379.25 436.79"
      />
      <path stroke="#9d3d5f" vector-effect="non-scaling-stroke" d="
  M 379.25 436.79
  L 368.65 436.45"
      />
      <path stroke="#ff596e" vector-effect="non-scaling-stroke" d="
  M 352.25 409.04
  Q 400.86 409.05 449.46 408.71
  Q 453.86 408.68 455.83 407.54
  C 460.64 404.79 460.69 396.69 456.15 393.75
  C 452.65 391.48 447.55 392.18 442.94 392.21
  Q 412.51 392.43 352.25 391.96"
      />
      <path stroke="#ff596e" vector-effect="non-scaling-stroke" d="
  M 352.00 377.29
  Q 400.39 377.51 448.78 377.20
  Q 453.09 377.17 455.07 376.23
  C 463.04 372.44 459.71 360.48 451.00 360.49
  Q 401.50 360.54 352.00 360.46"
      />
      <path stroke="#fe6b7f" vector-effect="non-scaling-stroke" d="
  M 352.21 344.75
  Q 362.13 344.33 372.08 344.49
  Q 376.22 344.56 378.23 343.44
  C 383.79 340.34 383.91 332.25 378.33 328.99
  C 373.81 326.36 358.04 327.74 352.25 327.71"
      />
      <path stroke="#8085aa" vector-effect="non-scaling-stroke" d="
  M 126.79 336.50
  Q 126.71 394.64 126.76 452.77
  C 126.76 455.23 127.07 458.06 126.78 461.25
  C 126.45 464.80 127.48 470.04 126.50 475.21"
      />
      <path stroke="#343361" vector-effect="non-scaling-stroke" d="
  M 109.41 463.29
  Q 110.37 452.82 110.21 442.30
  C 109.66 407.89 109.98 373.42 110.01 339.00
  Q 110.02 337.83 109.25 336.29"
      />
      <path stroke="#414074" vector-effect="non-scaling-stroke" d="
  M 379.25 436.79
  L 379.02 436.97
  A 0.74 0.71 -20.9 0 0 378.76 437.52
  L 379.09 478.73"
      />
      <path stroke="#5e59bc" vector-effect="non-scaling-stroke" d="
  M 402.75 436.79
  L 402.81 461.44
  A 0.71 0.71 0.0 0 0 403.28 462.11
  L 403.75 462.29"
      />
    </g>
    <path fill="#3b3868" d="
  M 132.81 0.00
  L 211.81 0.00
  C 206.25 8.43 206.95 11.41 212.54 19.50
  C 201.28 20.52 200.67 34.94 211.84 36.42
  Q 209.34 42.44 211.34 48.76
  A 0.69 0.69 0.0 0 0 212.00 49.25
  Q 255.22 49.26 298.44 49.23
  C 305.06 49.23 303.76 41.07 304.99 37.51
  C 310.90 20.33 329.66 24.15 343.78 23.99
  Q 353.32 23.89 358.09 25.23
  C 372.72 29.34 378.82 41.04 378.78 55.66
  Q 378.73 77.43 378.75 99.21
  L 368.00 99.04
  L 362.00 98.96
  Q 362.02 77.55 361.98 56.14
  C 361.95 44.82 358.34 40.98 346.65 40.99
  Q 334.63 41.00 322.71 41.01
  A 1.52 1.52 0.0 0 0 321.19 42.42
  C 320.13 57.97 310.42 66.05 295.56 66.13
  Q 251.12 66.38 206.79 66.20
  Q 206.54 63.58 205.97 61.01
  C 205.06 56.93 207.46 53.32 210.22 50.60
  A 0.55 0.54 47.0 0 0 210.24 49.85
  C 207.97 47.31 191.66 51.39 187.23 47.99
  C 183.77 45.34 185.00 41.55 184.01 38.29
  C 180.99 28.36 173.67 24.34 163.69 24.11
  Q 152.72 23.85 141.75 23.97
  Q 133.81 24.05 131.57 24.75
  Q 109.95 31.47 109.97 53.75
  Q 110.02 113.92 110.00 174.09
  C 110.00 175.24 110.89 187.91 110.33 188.24
  A 0.59 0.59 0.0 0 0 110.04 188.75
  L 110.00 199.96
  L 86.00 199.96
  Q 86.00 146.23 86.00 92.50
  Q 86.00 91.75 85.36 91.44
  Q 86.35 71.95 86.09 52.39
  C 85.73 24.88 105.93 3.93 132.81 0.00
  Z"
    />
    <path fill="#47477f" d="
  M 211.81 0.00
  L 352.56 0.00
  Q 387.68 3.25 400.07 35.49
  C 404.12 46.03 402.70 59.81 403.00 70.96
  Q 402.38 84.96 403.00 98.96
  L 378.75 99.21
  Q 378.73 77.43 378.78 55.66
  C 378.82 41.04 372.72 29.34 358.09 25.23
  Q 353.32 23.89 343.78 23.99
  C 329.66 24.15 310.90 20.33 304.99 37.51
  C 303.76 41.07 305.06 49.23 298.44 49.23
  Q 255.22 49.26 212.00 49.25
  A 0.69 0.69 0.0 0 1 211.34 48.76
  Q 209.34 42.44 211.84 36.42
  Q 263.20 36.72 276.94 36.43
  C 285.84 36.23 288.14 23.52 279.66 20.21
  Q 277.85 19.50 275.90 19.50
  L 212.54 19.50
  C 206.95 11.41 206.25 8.43 211.81 0.00
  Z"
    />
    <path fill="#5f5f9b" d="
  M 212.54 19.50
  L 275.90 19.50
  Q 277.85 19.50 279.66 20.21
  C 288.14 23.52 285.84 36.23 276.94 36.43
  Q 263.20 36.72 211.84 36.42
  C 200.67 34.94 201.28 20.52 212.54 19.50
  Z"
    />
    <path fill="#2d2d59" d="
  M 206.79 66.20
  L 206.00 66.29
  Q 198.01 66.42 190.03 66.05
  C 176.83 65.45 168.40 56.01 167.51 42.85
  Q 167.39 41.01 165.55 41.01
  Q 153.28 40.97 141.04 41.02
  C 129.36 41.07 126.98 46.53 126.99 57.65
  Q 127.04 128.83 126.96 200.00
  L 122.10 200.37
  L 110.00 199.96
  L 110.04 188.75
  A 0.59 0.59 0.0 0 1 110.33 188.24
  C 110.89 187.91 110.00 175.24 110.00 174.09
  Q 110.02 113.92 109.97 53.75
  Q 109.95 31.47 131.57 24.75
  Q 133.81 24.05 141.75 23.97
  Q 152.72 23.85 163.69 24.11
  C 173.67 24.34 180.99 28.36 184.01 38.29
  C 185.00 41.55 183.77 45.34 187.23 47.99
  C 191.66 51.39 207.97 47.31 210.24 49.85
  A 0.55 0.54 47.0 0 1 210.22 50.60
  C 207.46 53.32 205.06 56.93 205.97 61.01
  Q 206.54 63.58 206.79 66.20
  Z"
    />
    <path fill="#d3dcfb" d="
  M 206.00 66.29
  C 205.68 66.86 205.41 67.41 204.88 67.84
  Q 197.59 73.76 197.54 83.06
  Q 197.40 110.35 197.54 183.44
  Q 197.55 192.24 204.67 198.36
  Q 205.05 198.68 205.27 199.11
  L 205.75 200.04
  Q 166.35 200.54 126.96 200.00
  Q 127.04 128.83 126.99 57.65
  C 126.98 46.53 129.36 41.07 141.04 41.02
  Q 153.28 40.97 165.55 41.01
  Q 167.39 41.01 167.51 42.85
  C 168.40 56.01 176.83 65.45 190.03 66.05
  Q 198.01 66.42 206.00 66.29
  Z
  M 175.36 83.78
  A 7.92 7.92 0.0 0 0 167.44 75.86
  L 152.10 75.86
  A 7.92 7.92 0.0 0 0 144.18 83.78
  L 144.18 84.66
  A 7.92 7.92 0.0 0 0 152.10 92.58
  L 167.44 92.58
  A 7.92 7.92 0.0 0 0 175.36 84.66
  L 175.36 83.78
  Z"
    />
    <path fill="#e9efff" d="
  M 362.00 98.96
  Q 326.12 98.92 290.24 99.15
  C 280.37 99.21 272.29 103.67 268.86 113.20
  Q 267.49 117.02 267.49 124.45
  Q 267.52 166.79 267.48 209.12
  Q 267.47 216.79 268.84 220.71
  C 274.85 237.97 291.85 234.86 306.51 235.01
  C 309.10 235.04 311.26 235.79 312.66 237.84
  Q 314.73 240.87 324.32 255.59
  Q 326.58 259.05 328.88 260.21
  C 335.12 263.36 338.40 258.57 341.82 253.40
  Q 346.02 247.05 350.19 240.61
  C 353.35 235.73 356.17 233.65 361.96 235.25
  L 361.96 300.75
  Q 327.12 301.21 292.27 300.97
  C 281.50 300.89 272.65 304.38 268.89 315.04
  Q 267.48 319.02 267.49 327.11
  Q 267.51 369.54 267.50 411.97
  C 267.50 422.55 270.49 430.84 280.52 435.08
  C 287.57 438.06 299.01 437.12 307.01 436.96
  Q 310.75 436.88 313.10 440.42
  Q 318.55 448.59 323.92 456.84
  C 326.78 461.23 330.67 464.96 335.82 462.10
  Q 338.20 460.79 341.67 455.46
  Q 347.06 447.17 352.58 438.92
  Q 353.74 437.18 355.83 437.15
  L 362.00 437.04
  L 362.25 487.96
  Q 313.24 511.00 258.94 512.00
  L 251.69 512.00
  Q 227.98 511.29 204.79 506.68
  Q 200.85 502.53 198.90 497.35
  Q 197.61 493.91 197.59 487.96
  Q 197.40 422.35 197.54 356.73
  C 197.57 345.33 199.83 343.27 207.29 336.00
  C 214.02 335.61 222.99 336.79 229.08 334.86
  C 243.81 330.20 244.52 319.12 244.51 304.40
  Q 244.50 263.85 244.48 223.31
  C 244.48 209.18 235.33 200.04 221.42 200.24
  Q 213.57 200.35 205.75 200.04
  L 205.27 199.11
  Q 205.05 198.68 204.67 198.36
  Q 197.55 192.24 197.54 183.44
  Q 197.40 110.35 197.54 83.06
  Q 197.59 73.76 204.88 67.84
  C 205.41 67.41 205.68 66.86 206.00 66.29
  L 206.79 66.20
  Q 251.12 66.38 295.56 66.13
  C 310.42 66.05 320.13 57.97 321.19 42.42
  A 1.52 1.52 0.0 0 1 322.71 41.01
  Q 334.63 41.00 346.65 40.99
  C 358.34 40.98 361.95 44.82 361.98 56.14
  Q 362.02 77.55 362.00 98.96
  Z"
    />
    <path fill="#746bf9" d="
  M 403.00 70.96
  Q 419.68 83.79 434.25 98.96
  Q 418.63 99.69 403.00 98.96
  Q 402.38 84.96 403.00 70.96
  Z"
    />
    <rect fill="#42c3fc" x="144.18" y="75.86" width="31.18" height="16.72" rx="7.92"/>
    <path fill="#746bf9" d="
  M 85.36 91.44
  Q 86.00 91.75 86.00 92.50
  Q 86.00 146.23 86.00 199.96
  Q 66.53 200.04 47.01 199.99
  Q 38.99 199.96 35.11 201.35
  C 27.09 204.21 21.13 211.72 21.09 220.57
  Q 20.93 265.55 21.00 310.54
  Q 21.01 318.30 22.42 322.16
  Q 25.76 331.30 35.27 334.70
  C 41.83 337.05 62.49 333.47 65.68 338.30
  Q 71.79 347.59 77.92 356.81
  Q 81.22 361.76 86.00 362.29
  L 85.94 442.16
  A 0.57 0.57 0.0 0 1 85.72 442.61
  L 85.50 442.79
  C -13.47 346.03 -13.92 188.51 85.36 91.44
  Z"
    />
    <path fill="#ffb332" d="
  M 362.00 98.96
  L 368.00 99.04
  L 367.37 99.81
  Q 367.06 100.19 366.60 100.38
  Q 352.52 106.10 352.45 121.76
  Q 352.44 124.35 352.25 126.96
  Q 330.01 127.04 307.74 126.99
  C 296.45 126.97 295.36 143.89 308.78 143.77
  Q 330.64 143.58 352.50 143.96
  Q 351.88 151.21 352.50 158.46
  Q 330.76 158.43 309.02 158.62
  C 295.35 158.74 296.37 175.53 307.75 175.51
  Q 330.00 175.46 352.25 175.54
  L 352.50 190.21
  Q 330.13 190.29 307.75 190.24
  C 296.60 190.22 295.69 206.71 307.63 206.95
  Q 329.93 207.41 352.25 207.29
  C 352.00 219.10 354.16 228.82 366.26 233.46
  A 0.77 0.76 10.7 0 1 366.75 234.17
  L 366.75 234.96
  L 361.96 235.25
  C 356.17 233.65 353.35 235.73 350.19 240.61
  Q 346.02 247.05 341.82 253.40
  C 338.40 258.57 335.12 263.36 328.88 260.21
  Q 326.58 259.05 324.32 255.59
  Q 314.73 240.87 312.66 237.84
  C 311.26 235.79 309.10 235.04 306.51 235.01
  C 291.85 234.86 274.85 237.97 268.84 220.71
  Q 267.47 216.79 267.48 209.12
  Q 267.52 166.79 267.49 124.45
  Q 267.49 117.02 268.86 113.20
  C 272.29 103.67 280.37 99.21 290.24 99.15
  Q 326.12 98.92 362.00 98.96
  Z"
    />
    <path fill="#ffdd40" d="
  M 368.00 99.04
  L 378.75 99.21
  L 403.00 98.96
  Q 418.63 99.69 434.25 98.96
  Q 450.58 99.44 466.92 99.26
  C 477.26 99.15 485.97 102.96 489.59 113.21
  Q 491.02 117.26 491.01 125.95
  Q 491.00 161.86 491.23 197.75
  Q 491.24 198.48 491.29 199.25
  C 490.82 205.62 491.80 213.37 490.17 219.04
  Q 485.57 235.06 467.50 235.02
  Q 435.25 234.95 403.00 235.04
  L 378.75 235.04
  L 366.75 234.96
  L 366.75 234.17
  A 0.77 0.76 10.7 0 0 366.26 233.46
  C 354.16 228.82 352.00 219.10 352.25 207.29
  Q 400.54 207.24 448.80 207.21
  C 463.14 207.21 462.54 190.23 450.75 190.24
  Q 401.62 190.29 352.50 190.21
  L 352.25 175.54
  Q 400.40 175.47 448.55 175.50
  Q 453.11 175.50 455.16 174.52
  C 460.73 171.87 460.97 163.23 455.85 160.10
  C 453.16 158.45 448.70 158.48 445.75 158.49
  Q 399.13 158.54 352.50 158.46
  Q 351.88 151.21 352.50 143.96
  Q 362.34 143.59 372.22 143.77
  Q 375.57 143.83 377.22 143.17
  C 384.18 140.41 384.23 130.43 377.24 127.74
  Q 375.28 126.98 370.64 127.00
  Q 361.45 127.03 352.25 126.96
  Q 352.44 124.35 352.45 121.76
  Q 352.52 106.10 366.60 100.38
  Q 367.06 100.19 367.37 99.81
  L 368.00 99.04
  Z"
    />
    <path fill="#fcf9eb" d="
  M 352.25 126.96
  Q 361.45 127.03 370.64 127.00
  Q 375.28 126.98 377.24 127.74
  C 384.23 130.43 384.18 140.41 377.22 143.17
  Q 375.57 143.83 372.22 143.77
  Q 362.34 143.59 352.50 143.96
  Q 330.64 143.58 308.78 143.77
  C 295.36 143.89 296.45 126.97 307.74 126.99
  Q 330.01 127.04 352.25 126.96
  Z"
    />
    <path fill="#fceca9" d="
  M 352.50 158.46
  Q 399.13 158.54 445.75 158.49
  C 448.70 158.48 453.16 158.45 455.85 160.10
  C 460.97 163.23 460.73 171.87 455.16 174.52
  Q 453.11 175.50 448.55 175.50
  Q 400.40 175.47 352.25 175.54
  Q 330.00 175.46 307.75 175.51
  C 296.37 175.53 295.35 158.74 309.02 158.62
  Q 330.76 158.43 352.50 158.46
  Z"
    />
    <path fill="#fceca9" d="
  M 352.50 190.21
  Q 401.62 190.29 450.75 190.24
  C 462.54 190.23 463.14 207.21 448.80 207.21
  Q 400.54 207.24 352.25 207.29
  Q 329.93 207.41 307.63 206.95
  C 295.69 206.71 296.60 190.22 307.75 190.24
  Q 330.13 190.29 352.50 190.21
  Z"
    />
    <path fill="#746bf9" d="
  M 491.29 199.25
  Q 492.20 201.57 492.82 203.98
  Q 509.75 269.30 491.58 334.27
  Q 490.83 329.69 490.88 325.10
  C 490.99 314.60 487.13 306.00 476.99 302.47
  Q 472.63 300.96 461.83 300.98
  Q 432.42 301.05 403.00 300.96
  Q 402.38 268.00 403.00 235.04
  Q 435.25 234.95 467.50 235.02
  Q 485.57 235.06 490.17 219.04
  C 491.80 213.37 490.82 205.62 491.29 199.25
  Z"
    />
    <path fill="#1aa9e8" d="
  M 86.00 199.96
  L 110.00 199.96
  L 122.10 200.37
  Q 110.93 203.98 107.05 214.07
  Q 104.70 220.17 106.20 228.00
  Q 84.51 228.01 62.70 227.99
  Q 59.44 227.99 57.87 228.57
  C 51.15 231.04 50.49 240.54 56.89 243.74
  Q 58.96 244.77 63.29 244.76
  Q 84.51 244.71 105.75 244.79
  L 105.75 259.46
  Q 83.75 259.54 61.75 259.49
  C 54.75 259.47 49.58 266.55 53.86 272.61
  C 57.31 277.48 63.13 276.39 69.31 276.30
  Q 83.24 276.09 105.97 276.53
  L 105.76 291.17
  Q 84.66 291.34 63.57 291.20
  Q 58.83 291.17 56.65 292.36
  C 50.96 295.47 51.01 304.04 56.99 307.02
  Q 58.95 308.01 63.68 308.00
  Q 84.84 307.97 106.00 308.04
  Q 105.12 314.84 106.37 319.57
  Q 109.47 331.23 121.66 335.57
  L 109.25 336.29
  C 104.52 337.77 95.54 353.98 92.42 358.68
  Q 90.49 361.60 86.00 362.29
  Q 81.22 361.76 77.92 356.81
  Q 71.79 347.59 65.68 338.30
  C 62.49 333.47 41.83 337.05 35.27 334.70
  Q 25.76 331.30 22.42 322.16
  Q 21.01 318.30 21.00 310.54
  Q 20.93 265.55 21.09 220.57
  C 21.13 211.72 27.09 204.21 35.11 201.35
  Q 38.99 199.96 47.01 199.99
  Q 66.53 200.04 86.00 199.96
  Z"
    />
    <path fill="#42c3fc" d="
  M 126.96 200.00
  Q 166.35 200.54 205.75 200.04
  Q 213.57 200.35 221.42 200.24
  C 235.33 200.04 244.48 209.18 244.48 223.31
  Q 244.50 263.85 244.51 304.40
  C 244.52 319.12 243.81 330.20 229.08 334.86
  C 222.99 336.79 214.02 335.61 207.29 336.00
  L 127.43 335.81
  A 0.54 0.53 -79.7 0 0 126.93 336.16
  L 126.79 336.50
  L 126.74 336.17
  A 0.42 0.42 0.0 0 0 126.35 335.81
  L 121.66 335.57
  Q 109.47 331.23 106.37 319.57
  Q 105.12 314.84 106.00 308.04
  Q 153.70 307.96 201.40 308.01
  Q 206.31 308.01 208.44 307.00
  C 214.24 304.22 214.55 295.87 209.00 292.48
  Q 207.00 291.26 202.64 291.26
  Q 154.19 291.30 105.76 291.17
  L 105.97 276.53
  Q 153.42 276.48 200.78 276.46
  Q 205.60 276.46 207.51 275.79
  C 216.84 272.49 213.59 259.49 203.75 259.50
  Q 154.75 259.53 105.75 259.46
  L 105.75 244.79
  Q 115.83 244.72 125.90 244.75
  Q 128.95 244.76 130.51 244.15
  C 137.03 241.59 137.84 232.66 131.75 229.21
  C 127.18 226.61 111.99 227.99 106.20 228.00
  Q 104.70 220.17 107.05 214.07
  Q 110.93 203.98 122.10 200.37
  L 126.96 200.00
  Z"
    />
    <path fill="#b8f0ff" d="
  M 106.20 228.00
  C 111.99 227.99 127.18 226.61 131.75 229.21
  C 137.84 232.66 137.03 241.59 130.51 244.15
  Q 128.95 244.76 125.90 244.75
  Q 115.83 244.72 105.75 244.79
  Q 84.51 244.71 63.29 244.76
  Q 58.96 244.77 56.89 243.74
  C 50.49 240.54 51.15 231.04 57.87 228.57
  Q 59.44 227.99 62.70 227.99
  Q 84.51 228.01 106.20 228.00
  Z"
    />
    <path fill="#3b3868" d="
  M 366.75 234.96
  L 378.75 235.04
  L 378.75 300.96
  L 368.84 301.37
  L 361.96 300.75
  L 361.96 235.25
  L 366.75 234.96
  Z"
    />
    <path fill="#47477f" d="
  M 378.75 235.04
  L 403.00 235.04
  Q 402.38 268.00 403.00 300.96
  L 378.75 300.96
  L 378.75 235.04
  Z"
    />
    <path fill="#8ae1ff" d="
  M 105.75 259.46
  Q 154.75 259.53 203.75 259.50
  C 213.59 259.49 216.84 272.49 207.51 275.79
  Q 205.60 276.46 200.78 276.46
  Q 153.42 276.48 105.97 276.53
  Q 83.24 276.09 69.31 276.30
  C 63.13 276.39 57.31 277.48 53.86 272.61
  C 49.58 266.55 54.75 259.47 61.75 259.49
  Q 83.75 259.54 105.75 259.46
  Z"
    />
    <path fill="#8ae1ff" d="
  M 105.76 291.17
  Q 154.19 291.30 202.64 291.26
  Q 207.00 291.26 209.00 292.48
  C 214.55 295.87 214.24 304.22 208.44 307.00
  Q 206.31 308.01 201.40 308.01
  Q 153.70 307.96 106.00 308.04
  Q 84.84 307.97 63.68 308.00
  Q 58.95 308.01 56.99 307.02
  C 51.01 304.04 50.96 295.47 56.65 292.36
  Q 58.83 291.17 63.57 291.20
  Q 84.66 291.34 105.76 291.17
  Z"
    />
    <path fill="#e80054" d="
  M 361.96 300.75
  L 368.84 301.37
  C 356.07 305.16 351.59 315.19 352.25 327.71
  Q 330.00 327.79 307.75 327.74
  C 296.42 327.71 295.39 344.50 308.68 344.48
  Q 330.44 344.45 352.21 344.75
  L 352.00 360.46
  Q 331.02 360.51 310.04 360.52
  Q 305.35 360.52 303.24 361.57
  C 297.90 364.22 297.62 372.86 302.62 375.89
  Q 304.84 377.23 309.29 377.23
  Q 330.64 377.23 352.00 377.29
  L 352.25 391.96
  Q 330.57 391.92 308.89 392.14
  C 295.37 392.27 296.21 409.02 308.25 409.00
  Q 330.25 408.97 352.25 409.04
  Q 352.05 416.62 352.62 419.07
  Q 355.67 432.31 368.65 436.45
  L 362.00 437.04
  L 355.83 437.15
  Q 353.74 437.18 352.58 438.92
  Q 347.06 447.17 341.67 455.46
  Q 338.20 460.79 335.82 462.10
  C 330.67 464.96 326.78 461.23 323.92 456.84
  Q 318.55 448.59 313.10 440.42
  Q 310.75 436.88 307.01 436.96
  C 299.01 437.12 287.57 438.06 280.52 435.08
  C 270.49 430.84 267.50 422.55 267.50 411.97
  Q 267.51 369.54 267.49 327.11
  Q 267.48 319.02 268.89 315.04
  C 272.65 304.38 281.50 300.89 292.27 300.97
  Q 327.12 301.21 361.96 300.75
  Z"
    />
    <path fill="#ff4155" d="
  M 378.75 300.96
  L 403.00 300.96
  Q 432.42 301.05 461.83 300.98
  Q 472.63 300.96 476.99 302.47
  C 487.13 306.00 490.99 314.60 490.88 325.10
  Q 490.83 329.69 491.58 334.27
  C 491.36 335.16 491.02 336.00 491.02 336.93
  Q 490.95 372.22 491.04 407.51
  Q 491.06 418.45 489.54 422.81
  C 485.85 433.35 476.79 436.90 466.20 436.76
  Q 449.23 436.54 432.25 437.04
  L 402.75 436.79
  L 379.25 436.79
  L 368.65 436.45
  Q 355.67 432.31 352.62 419.07
  Q 352.05 416.62 352.25 409.04
  Q 400.86 409.05 449.46 408.71
  Q 453.86 408.68 455.83 407.54
  C 460.64 404.79 460.69 396.69 456.15 393.75
  C 452.65 391.48 447.55 392.18 442.94 392.21
  Q 412.51 392.43 352.25 391.96
  L 352.00 377.29
  Q 400.39 377.51 448.78 377.20
  Q 453.09 377.17 455.07 376.23
  C 463.04 372.44 459.71 360.48 451.00 360.49
  Q 401.50 360.54 352.00 360.46
  L 352.21 344.75
  Q 362.13 344.33 372.08 344.49
  Q 376.22 344.56 378.23 343.44
  C 383.79 340.34 383.91 332.25 378.33 328.99
  C 373.81 326.36 358.04 327.74 352.25 327.71
  C 351.59 315.19 356.07 305.16 368.84 301.37
  L 378.75 300.96
  Z"
    />
    <path fill="#fc95a9" d="
  M 352.25 327.71
  C 358.04 327.74 373.81 326.36 378.33 328.99
  C 383.91 332.25 383.79 340.34 378.23 343.44
  Q 376.22 344.56 372.08 344.49
  Q 362.13 344.33 352.21 344.75
  Q 330.44 344.45 308.68 344.48
  C 295.39 344.50 296.42 327.71 307.75 327.74
  Q 330.00 327.79 352.25 327.71
  Z"
    />
    <path fill="#2d2d59" d="
  M 121.66 335.57
  L 126.35 335.81
  A 0.42 0.42 0.0 0 1 126.74 336.17
  L 126.79 336.50
  Q 126.71 394.64 126.76 452.77
  C 126.76 455.23 127.07 458.06 126.78 461.25
  C 126.45 464.80 127.48 470.04 126.50 475.21
  Q 117.43 469.72 109.41 463.29
  Q 110.37 452.82 110.21 442.30
  C 109.66 407.89 109.98 373.42 110.01 339.00
  Q 110.02 337.83 109.25 336.29
  L 121.66 335.57
  Z"
    />
    <path fill="#d3dcfb" d="
  M 207.29 336.00
  C 199.83 343.27 197.57 345.33 197.54 356.73
  Q 197.40 422.35 197.59 487.96
  Q 197.61 493.91 198.90 497.35
  Q 200.85 502.53 204.79 506.68
  Q 162.90 497.66 126.50 475.21
  C 127.48 470.04 126.45 464.80 126.78 461.25
  C 127.07 458.06 126.76 455.23 126.76 452.77
  Q 126.71 394.64 126.79 336.50
  L 126.93 336.16
  A 0.54 0.53 -79.7 0 1 127.43 335.81
  L 207.29 336.00
  Z"
    />
    <path fill="#3b3868" d="
  M 109.25 336.29
  Q 110.02 337.83 110.01 339.00
  C 109.98 373.42 109.66 407.89 110.21 442.30
  Q 110.37 452.82 109.41 463.29
  Q 96.45 453.96 85.50 442.79
  L 85.72 442.61
  A 0.57 0.57 0.0 0 0 85.94 442.16
  L 86.00 362.29
  Q 90.49 361.60 92.42 358.68
  C 95.54 353.98 104.52 337.77 109.25 336.29
  Z"
    />
    <path fill="#ff7186" d="
  M 352.00 360.46
  Q 401.50 360.54 451.00 360.49
  C 459.71 360.48 463.04 372.44 455.07 376.23
  Q 453.09 377.17 448.78 377.20
  Q 400.39 377.51 352.00 377.29
  Q 330.64 377.23 309.29 377.23
  Q 304.84 377.23 302.62 375.89
  C 297.62 372.86 297.90 364.22 303.24 361.57
  Q 305.35 360.52 310.04 360.52
  Q 331.02 360.51 352.00 360.46
  Z"
    />
    <path fill="#ff7186" d="
  M 352.25 391.96
  Q 412.51 392.43 442.94 392.21
  C 447.55 392.18 452.65 391.48 456.15 393.75
  C 460.69 396.69 460.64 404.79 455.83 407.54
  Q 453.86 408.68 449.46 408.71
  Q 400.86 409.05 352.25 409.04
  Q 330.25 408.97 308.25 409.00
  C 296.21 409.02 295.37 392.27 308.89 392.14
  Q 330.57 391.92 352.25 391.96
  Z"
    />
    <path fill="#3b3868" d="
  M 368.65 436.45
  L 379.25 436.79
  L 379.02 436.97
  A 0.74 0.71 -20.9 0 0 378.76 437.52
  L 379.09 478.73
  Q 371.14 483.81 362.25 487.96
  L 362.00 437.04
  L 368.65 436.45
  Z"
    />
    <path fill="#47477f" d="
  M 379.25 436.79
  L 402.75 436.79
  L 402.81 461.44
  A 0.71 0.71 0.0 0 0 403.28 462.11
  L 403.75 462.29
  Q 392.11 471.22 379.09 478.73
  L 378.76 437.52
  A 0.74 0.71 -20.9 0 1 379.02 436.97
  L 379.25 436.79
  Z"
    />
    <path fill="#746bf9" d="
  M 402.75 436.79
  L 432.25 437.04
  Q 419.03 450.83 403.75 462.29
  L 403.28 462.11
  A 0.71 0.71 0.0 0 1 402.81 461.44
  L 402.75 436.79
  Z"
    />
  </svg>
</template>
<script>
export default {
  name: "IconChats",
  props: {width: {default: 24}},
};
</script>
