<template>
  <div class="page_content">
    <div
      class="page_content_inner"
      :style="{ 'max-width': max_width !== null ? max_width + 'px' : '100%' }"
    >
      <slot></slot>
    </div>
    <div v-if="loading" class="page_loader">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/ui/Loader";
export default {
  name: "Page",
  components: { Loader },
  props: {
    max_width: {
      default: null,
    },
    loading: {
      default: false,
    },
  },
};
</script>

<style scoped lang="less">
.page_content {
  flex: 1;
  overflow-y: auto;
  padding: 50px;
  position: relative;
}

.page_content_inner {
}

.page_loader {
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}
</style>
