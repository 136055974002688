<template>
  <div class="chat_list_wrapper">
    <div class="chat_list_chats">
      <transition-group
          name="list"
          tag="ul"
          class="list-group list-group-flush list"
      >
        <li
            @click="go(item.id)"
            class="list-group-item px-0 chat_item"
            :class="[
            { chat_item_active: item.id === parseInt($route.params.id) },
            { chat_item_show: item.status === 'active' || show_closed }
          ]"
            v-for="(item, $i) in items"
            :key="item.id + 'list_item'"
        >

          <div class="align-items-center chat_item_row">
            <div
                class="chat_list_active_green"
                v-if="item.status === 'active'"
            ></div>
            <div class="col-auto chat_list_img">
              <div class="avatar">
                <div class="avatar_box">
                <img
                    :src="item.users[0].public_user_profile.avatar.default"
                />
                  <div class="avatar_source">
                    <img src="../../assets/images/avito.svg">
                  </div>
                </div>
              </div>
            </div>
            <div class="chat_item_right">
              <div class="col ml--2 chat_list_text">
                <h4 class="mb-0">{{ item.users[0].name }}</h4>
                <small
                ><strong v-if="item.direction === 'in'">👨 </strong
                >{{ item.last_message.content.text }}</small
                >
              </div>
              <div class="col-auto chat_list_right">
                {{ formatDate(item.created) }}
              </div>
            </div>
          </div>
          <div
              class="chat_list_closed_item"
              v-if="
              items[$i + 1] &&
                item.status === 'active' &&
                items[$i + 1].status !== 'active' &&
                show_closed
            "
          >
            закрытые
          </div>
        </li>
      </transition-group>
      <div v-show="!items.length" class="chat_list_no_work ">
        <i class="ni ni-check-bold text-blue"></i>
        <div>Нет открытых чатов...</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isThisWeek from "date-fns/isThisWeek";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import ru from "date-fns/locale/ru";

export default {
  name: "ChatList",
  data() {
    return {
      items: [],
      last_date: null,
      interval: {},
      can_update: true,
      loading: false,
      show_closed: false
    };
  },
  computed: {
    no_chats() {
      console.log(
          this.items.filter(el => el.status === "active").length,
          "----"
      );
      if (
          !this.show_closed &&
          !this.items.filter(el => el.status === "active").length
      )
        return true;
      else return false;
    }
  },
  methods: {
    go(id) {
      this.$router.push({name: "chats", params: {id: id}});
    },
    load() {
      if (!this.loading) {
        if (this.can_update) {
          this.loading = true;
          axios.post("vacancies/get-chat-list", {date: null}).then(res => {
            this.items = res.data.list;
            console.log(this.items)
            this.loading = false;
          });
        }
      }
    },
    formatDate(value) {
      if (value) {
        let d = new Date(value * 1000);
        if (isToday(d)) {
          return format(d, "k:mm");
        } else if (differenceInCalendarDays(new Date(), d) < 6) {
          return format(d, "EEEEEE", {locale: ru});
        } else {
          return format(d, "d.MM.yy");
          s;
        }
      }
    }
  },
  mounted() {
    this.load();
  },
  destroyed() {
  }
};
</script>

<style scoped lang="less">
@import "../../assets/vars";
.chat_list_wrapper {
  height: 100%;
  min-width: 260px;
  width: 260px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.chat_list_chats {
  overflow-y: auto;
  flex: 1;
  //transition: 0.2s;
  position: relative;
}

.avatar {
  img {
    width: 45px;
    border-radius: 100px;
  }
}

.chat_list_top {
  padding: 12px 6px !important;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  display: flex;
  align-items: center;
}

.chat_item {
  cursor: pointer;
  padding: 6px 6px !important;
  padding-right: 0 !important;

  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.chat_list_closed_item {
  color: #999999;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 16px;
  padding-top: 50px;
}

.chat_item_row {
  display: flex;
  border-radius: 10px;
}

.chat_item_active {
  opacity: 1;

  color: #fff;

  .chat_item_row {
    background: #2ec0c3;
  }

  .avatar {
    background: #fff;
    color: #2ec0c3;

  }

  .chat_list_active_green {
    opacity: 0;
  }

  h4 {
    color: #fff;
  }
}

.chat_item_show {
  display: block;
}

.list {
  padding: 0;
  list-style: none;
}

.chat_list_img {
  display: flex;
  padding: 4px 4px;
  padding-right: 10px;
}

.chat_item_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  overflow: hidden;
  color: @color_gray_links;
  border-bottom: 1px solid @color_lines_gray;

}



.chat_list_right {
  align-self: flex-start;
  padding-top: 8px;
  font-size: 12px;
}

.chat_list_text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  h4 {
    color: @color_black;
    font-weight: 600;
  }
}

.list-move {
  transition: transform 0.5s;
}

.chat_list_chats::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.chat_list_chats::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.chat_list_chats::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent;
  background-clip: content-box;
}

.chat_list_active_green {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  background: #00dea2;
  align-self: center;
  position: absolute;
}

.chat_list_label {
  input {
    margin-right: 7px;
  }

  cursor: pointer;
  margin-bottom: 0;
  font-size: 13px;
}

.chat_list_no_work {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  color: #999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  i {
    font-size: 50px;
  }
}
</style>
