<template>
  <div style="width: 100%; text-align: center">
    <div :class="divClass">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {},
    time: { default: 1000 },
    divClass: { default: "lds-ellipsis" },
  },

  methods: {},
};
</script>

<style>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: auto;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

.lds-ellipsis_top {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  margin: auto;
}

.lds-ellipsis_top div {
  position: absolute;
  top: 27px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #515760;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis_top div:nth-child(1) {
  left: 3px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis_top div:nth-child(2) {
  left: 3px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis_top div:nth-child(3) {
  left: 19px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis_top div:nth-child(4) {
  left: 35px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>
