<template>
  <div class="page_header_wrapper">
    <div class="page_header">
      <div class="page_header_inner">
        <div class="page_header_title">
          <div class="page_header_back" v-if="back_url">
            <router-link :to="back_url"
              ><i style="margin-top: 2px" class="fas fa-lg fa-angle-left"></i
              ><span style="margin-left: 5px" v-if="back_text">{{
                back_text
              }}</span></router-link
            >
          </div>
          <div>{{ title }}</div>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: { default: null },
    back_text: { default: null },
    back_url: { default: null },
  },
};
</script>

<style scoped lang="less">
@import "../assets/vars";
.page_header {
  width: 100%;
  border-bottom: 1px solid @color_lines_gray;

  background: white;
}

.page_header_inner {
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.page_header_title {
  font-size: 24px;
  font-weight: 600;
}

.page_header_back {
  font-size: 14px;

  a {
    text-decoration: none;
  }
}
</style>
