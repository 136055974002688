import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: false,
    loading: false,
    modals: {
      alert_modal: null
    }
  },
  mutations: {
    auth_success(state) {
      state.auth = true
    },
    auth_logout(state) {
      state.auth = false
    },
    loading(state, data){
      state.loading = data
    },
    alert_modal(state, data){
      state.modals.alert_modal = data
    }
  },
  actions: {
    auth_request({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        // The Promise used for router redirect in login
        axios.post("login", user).then(resp => {
          console.log(resp)
          if (resp.data.success) {
            const token = resp.data.token;
            const id = resp.data.id;
            localStorage.setItem("user-token", token); // store the token in localstorage
            localStorage.setItem("user-id", id); // store the token in localstorage

            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success");
            resolve(resp);
          } else {
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-id");
            reject(resp);
          }
        });
      });
    },
    auth_logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit("auth_logout");
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-id");
        // remove the axios default header
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    reg({ commit, dispatch }, user) {
      return new Promise((resolve, reject) => {
        axios.post("reg", user).then(resp => {
          console.log(resp);
          if (resp.data.success === 1) {
            const token = resp.data.token;
            const id = resp.data.id;
            localStorage.setItem("user-token", token); // store the token in localstorage
            localStorage.setItem("user-id", id); // store the token in localstorage
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success");
            resolve(resp);
          } else {
            reject(resp);
          }
        });
      });
    },
    alert({commit}, text){
      commit('alert_modal', text)
      this.$modal.show('alert_modal');
    }
  }
})
