<template>
  <div class="errors_box_wrapper">
    <div v-for="(er, $i) in errors" :key="$i">{{ er }}</div>
  </div>
</template>

<script>
export default {
  name: "ErrorsBox",
  props: { errors: {} },
};
</script>

<style scoped lang="less">
@import "../assets/vars";
.errors_box_wrapper {
  color: @color_red;
  margin-bottom: 20px;
}
</style>
