import BaseButton from "@/components/ui/BaseButton";
import BaseInput from "@/components/ui/BaseInput";
import CheckBox from "@/components/ui/CheckBox";

export default {
    install(Vue) {
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(CheckBox.name, CheckBox);
    }
};