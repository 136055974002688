<template>
  <svg
    :width="width"
    viewBox="0 0 22 22"
    fill="#BEBEBE"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2563 4.33394L14.5904 11.0005L21.2563 17.6667C22.2478 18.6586 22.2478 20.2654 21.2563 21.2573C20.7609 21.7527 20.1113 22.0006 19.4621 22.0006C18.8119 22.0006 18.1623 21.753 17.6673 21.2573L10.9999 14.5903L4.3331 21.2572C3.83775 21.7526 3.18817 22.0005 2.5384 22.0005C1.88882 22.0005 1.23968 21.753 0.743894 21.2572C-0.247622 20.2657 -0.247622 18.6589 0.743894 17.6667L7.40958 11.0004L0.743515 4.33394C-0.248001 3.34243 -0.248001 1.73531 0.743515 0.743799C1.73484 -0.246957 3.34101 -0.246957 4.33272 0.743799L10.9999 7.41036L17.6665 0.743799C18.6584 -0.246957 20.2648 -0.246957 21.2559 0.743799C22.2478 1.73531 22.2478 3.34243 21.2563 4.33394Z"
    />
  </svg>
</template>
<script>
export default {
  name: "IconClose",
  props: { width: { default: 24 } },
};
</script>
