import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store/index.js";
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import axios from "axios";
import globalComponents from "@/plugins/globalComponents";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import SelectIndicator from "@/components/SelectIndicator";
import NotificationPlugin from "@/components/NotificationPlugin/index"
import '@/assets/main.less'

vSelect.props.components.default = () => ({
    OpenIndicator: SelectIndicator,
});

Vue.use(VueVirtualScroller)
Vue.component('v-select', vSelect)
Vue.use(globalComponents)
Vue.use(NotificationPlugin);

const eventBus = {}

eventBus.install = function (Vue) {
    Vue.prototype.$bus = new Vue()
}

Vue.use(eventBus)

const token = localStorage.getItem("user-token");
const id = localStorage.getItem("user-id");
if (token && id) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    store.commit("auth_success",);
}

axios.defaults.baseURL = 'https://hr-api.gdejob.com/api/v1/hr/';

Vue.config.productionTip = false

new Vue({
    router,
    store,
    components: { App },
    template: "<App/>",
    render: h => h(App)
}).$mount("#app");